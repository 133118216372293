import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { pathENUM, routeTitleMap } from 'registrators/registrators'
import type { RootState } from 'store/store'

import { DEFAULT_OBJECTS_PER_PAGE } from '../../numberConsts'
import { LinkContainer, LinkWrapp, SLink } from './PagesList.styled'

export const PagesList = () => {
  const filters = useSelector((state: RootState) => state.filters)
  const elementPerPageInPrograms: number =
    filters.programsFilters?.per_page?.value || DEFAULT_OBJECTS_PER_PAGE
  const elementPerPageInEvents: number =
    filters.eventsFilters?.per_page?.value || DEFAULT_OBJECTS_PER_PAGE
  const elementPerPageInUnits: number =
    filters.unitsFilters?.per_page?.value || DEFAULT_OBJECTS_PER_PAGE
  const linkConfig = useMemo(
    () => [
      {
        title: routeTitleMap[pathENUM.programs],
        path: pathENUM.programs,
        query: `/?page=1&per_page=${elementPerPageInPrograms}`,
      },
      {
        title: routeTitleMap[pathENUM.news],
        path: pathENUM.news,
        query: `/?page=1&per_page=9`,
      },
      {
        title: routeTitleMap[pathENUM.events],
        path: pathENUM.events,
        query: `/?page=1&per_page=${elementPerPageInEvents}&all_dates=false&only_future_dates=true&sort_by=%5B"date_from"%5D`,
      },
      {
        title: routeTitleMap[pathENUM.organizations],
        path: pathENUM.organizations,
        query: `/?page=1&per_page=${elementPerPageInUnits}`,
      },
    ],
    [elementPerPageInEvents, elementPerPageInPrograms, elementPerPageInUnits],
  )
  const pagesList = useMemo(
    () =>
      linkConfig.map((link) => (
        <SLink
          className="SLinkMobile"
          key={link.path}
          href={`${link.path}${link.query ?? ''}`}>
          {link.title}
        </SLink>
      )),
    [linkConfig],
  )
  return (
    <LinkWrapp>
      <LinkContainer>{pagesList}</LinkContainer>
    </LinkWrapp>
  )
}
