export enum radioItemENUM {
  feedback = 'feedback',
  registrationForTraining = 'registrationForTraining',
  registrationForEvent = 'registrationForEvent',
  talents = 'talents',
  esia = 'esia',
  organizations = 'organizations',
  instructionsForParents = 'instructionsForParents',
  instructionsForOrganizations = 'instructionsForOrganizations',
}
