// eslint-disable-next-line import/no-unresolved
import type { Property } from 'csstype'
import type { ThemedStyledProps } from 'styled-components'

import type { Theme } from '../../theme'

export type SpacingType = Property.Padding | Property.Margin | number

export const getSpacing =
  (spacing: SpacingType | Array<SpacingType>) =>
  (props: ThemedStyledProps<any, Theme>) => {
    if (Array.isArray(spacing)) {
      const spacingArray = spacing.reduce(
        (previousValue: Array<string>, currentValue: SpacingType) => {
          if (typeof currentValue === 'string') {
            previousValue.push(currentValue)
          } else {
            previousValue.push(
              `${props.theme.spacing.default * currentValue}px`,
            )
          }
          return previousValue
        },
        [],
      )
      return spacingArray.join(' ')
    }

    if (typeof spacing === 'string') return spacing

    return `${props.theme.spacing.default * spacing}px`
  }
