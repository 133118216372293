import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

import type {
  IEvent,
  IParamsCommentsNew,
} from '../api/interfaces/events/interfaces.model'
import type { IOrganization } from '../api/interfaces/organizations/interfaces.model'
import type { IProgram } from '../api/interfaces/programs/interfaces.model'
import type { IMunicipality } from '../components/Filters/MunicipalityFilter/MunicipalityFilter.model'
import type { IChildInfo } from '../store/reducers/children/children.model'
import type { IDeclarationData } from '../store/reducers/declaration/declarations.model'
import type {
  IChildDataRecord,
  IDeclarationDataDelete,
  IDoc,
  IUser,
} from '../store/reducers/user/user.model'
import axiosTemplate, { nextApi } from './securedAxios'

// Бэк делит по 10 элементов на страницу, для того, чтобы все выводить, используем данную константу
const DEFAULT_MUNICIPALITY_DISTRICTS_COUNT = 30

export const commentsAPI = {
  getProgramComment: (id: number) =>
    axiosTemplate.get(`comments/program/${id}`),
  getEventComment: (id: number) => axiosTemplate.get(`comments/event/${id}`),
  postsComments: (params: IParamsCommentsNew) =>
    axiosTemplate.post(
      'comments/new/',
      {
        ...params,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ),
}

export const userAPI = {
  me: () => nextApi.get<IUser>('api/user/me'),
  logout: () => nextApi.get<void>('api/user/esia_logout'),
  postChildDocument: (body: IDoc) =>
    nextApi.post('api/child_doc_info', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  userLocation: (lat: number, lon: number) =>
    nextApi.get(`api/user/userLocation?lat=${lat}&lon=${lon}`),
  getChildren: async () => {
    const response = await nextApi.get<IChildInfo[]>('api/child_info')
    return response.data
  },
  postChild: (body: IChildDataRecord) =>
    nextApi.post('api/child_info', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getChildDataFromESIA: async () => {
    const response = await nextApi.get('api/esia_children')
    return response.data.children
  },
  addChildrenFromEsiaToLK: (id: number) =>
    nextApi.post(
      'api/esia_children',
      { id },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      },
    ),
  deleteChild: (id: number) =>
    nextApi.delete(`api/child/${id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      data: { id },
    }),
  deleteDocument: (id: number) =>
    nextApi.delete(`api/document/${id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      data: { id },
    }),
  getUserDeclarations: async () => {
    const response = await nextApi.get<{ data: IDeclarationData }>(
      'api/declaration/children_declarations',
    )
    return response.data.data
  },
  deleteUserDeclaration: (body: IDeclarationDataDelete) =>
    nextApi.post('api/declaration/cancel', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }),
}

export const statisticsAPI = {
  statistics: () => axiosTemplate.get('statistics'),
}

export const durationAPI = {
  duration: () => axiosTemplate.get('programs/program_durations'),
}

export const formEducationAPI = {
  formEdu: () => axiosTemplate.get('programs/edu_types'),
}

export const mainPageAPI = {
  getPrograms: () => axiosTemplate.get('programs/data?page=1&per_page=3'),
  getEvents: () =>
    axiosTemplate.get(
      'events/data?page=1&per_page=3&sort_by=[%22date_from%22]&all_dates=false',
    ),
  getNews: () => axiosTemplate.get('posts/data?page=1&per_page=3'),
}

export const pagesDataAPI = {
  getPrograms: (params: Record<string, string | number>) => {
    const url = 'programs/data'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getOrganizations: (params: Record<string, string | number>) => {
    const url = '/dicts/units'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getOrganisationsByDistrict: (
    params: Record<string, string | number>,
    id: number,
  ) => {
    const url = `/dicts/units?is_mo=true&parentGUID=${id}`
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getProgramsForMap: (params: Record<string, string | number>) =>
    axiosTemplate.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_URL}/map/programs?all_dates=true`,
      {
        params,
      },
    ),
  getUnitsForMap: (params: Record<string, string | number>) =>
    axiosTemplate.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_URL}/map/units?all_dates=true`,
      {
        params,
      },
    ),
  getEventsForMap: (params: Record<string, string | number>) =>
    axiosTemplate.get(
      `${process.env.NEXT_PUBLIC_REACT_APP_URL}/map/events?only_future_dates=true`,
      {
        params,
      },
    ),
  getProgram: (id: number) => axiosTemplate.get(`programs/${id}/data`),
  getEvents: (params: Record<string, string | number>) => {
    const url = 'events/data'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getRecomendedEvent: (id: number) =>
    axiosTemplate.get(`/events/${id}/relevant_events`),
  getRecomendedPrograms: (id: number) =>
    axiosTemplate.get(`/programs/${id}/relevant_programs`),
  getEvent: (id: number) => axiosTemplate.get(`events/${id}/data`),
  getMultipleEvents: async (ids: number[]) => {
    try {
      const response = await axios.all(
        ids.map((id) =>
          axiosTemplate.get<{ data: IEvent }>(`events/${id}/data`),
        ),
      )
      return response.map((event) => event.data.data)
    } catch (e) {
      console.error(e)
      return undefined
    }
  },
  getMultipleUnits: async (ids: number[]) => {
    try {
      const response = await axios.all(
        ids.map((id) =>
          axiosTemplate.get<{ data: IOrganization }>(`units/${id}/data`),
        ),
      )
      return response.map((event) => event.data.data)
    } catch (e) {
      console.error(e)
      return undefined
    }
  },
  getMultiplePrograms: async (ids: number[]) => {
    try {
      const response = await axios.all(
        ids.map((id) =>
          axiosTemplate.get<{ data: IProgram }>(`programs/${id}/data`),
        ),
      )
      return response.map((event) => event.data.data)
    } catch (e) {
      console.error(e)
      return undefined
    }
  },
  getOrganisationForSelect: (config: AxiosRequestConfig) =>
    axiosTemplate.get('units/data', { ...config }),
  getOrganizationsMap: () => axiosTemplate.get('front_api/unit_list'),
  getOrganization: (id: number) => axiosTemplate.get(`units/${id}/data`),
  getTalents: (params: Record<string, string | number>) => {
    const url = 'genius/children'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getInstructions: () => axiosTemplate.get('/instructions/data'),
  getInstructionsData: (id: number) =>
    axiosTemplate.get(`/instructions/${id}/data`),
  getUsefulInformation: () => axiosTemplate.get('/documents/data'),
  getTalentsResourses: () => axiosTemplate.get('/genius/source/data'),
  getTalentResoursesData: (id: number) =>
    axiosTemplate.get(`/genius/source/${id}/data`),
  getTalentEvent: (params: Record<string, string | number>) => {
    const url = '/genius/dict/events_list/'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getTalentEventById: (id: number) => {
    const url = `/genius/events/${id}/data`
    return axiosTemplate.get(url)
  },
  getNews: (id: number) => axiosTemplate.get(`/posts/${id}/data`),
  getTeachersByUnitId: (id: number) =>
    axiosTemplate.get(`teacher_list/?unit_id=${id}`),
  getAchievementsByUnitId: (id: number) =>
    axiosTemplate.get(`achievements/?unit_id=${id}`),
  getNewses: (params: Record<string, string | number>) => {
    const url = 'posts/data'
    return axiosTemplate.get(url, {
      params: { ...params },
    })
  },
  getAdaptationTypes: () => axiosTemplate.get('core/adaptation_types'),
}

export const postFeedback = {
  postFeedbackUser: (body: any) =>
    axiosTemplate.post('/feedback/send/', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
}

export const postData = {
  postRecordProgram: (body: any) =>
    nextApi.post('api/programs/declaration', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  postRecordEvent: (body: any) =>
    nextApi.post('api/events/event_declarations', body, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
}

export const filtersAPI = {
  mainFilter: (value: string, config: AxiosRequestConfig) =>
    axiosTemplate.get(`/search/tips?query=${value}`, { ...config }),
  getDirections: () => axiosTemplate.get('front_api/direction_list'),
  getProfiles: () => axiosTemplate.get('front_api/profile_list'),
  getKinds: () => axiosTemplate.get('core/payment_types'),
  getDistricts: () => axiosTemplate.get('/genius/districts'),
  getEventProgramProfiles: () =>
    axiosTemplate.get('/programs/program_profiles'),
  getMunicipality: (value: string, config: AxiosRequestConfig) =>
    axiosTemplate.get(`portal_settings/gar/addrobj/?name=${value}`, {
      ...config,
    }),
  getMO: (value: string, config: AxiosRequestConfig) =>
    axiosTemplate.get<{ data: IMunicipality[] }>(`dicts/mo?name=${value}`, {
      ...config,
    }),
  getOrganization: (value: string, config: AxiosRequestConfig) =>
    axiosTemplate.get(`dicts/units?search=${value}`, { ...config }),
  getEventLevels: () => axiosTemplate.get('/genius/event_levels'),
  getEventKinds: () => axiosTemplate.get('/genius/event_kinds'),
  getRealizationForm: () =>
    axiosTemplate.get('/genius/event_realization_forms'),
  getTags: () => axiosTemplate.get('/posts/tags'),
  getMunicipalityDistrict: () =>
    axiosTemplate.get(
      `dicts/mo?page=1&per_page=${DEFAULT_MUNICIPALITY_DISTRICTS_COUNT}`,
    ),
}
