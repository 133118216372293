enum DistrictNameWithoutCoordinates {
  AZOVSKY = 'Азовский район',
  AKSAISKY = 'Аксайский район',
  BAGAEVSKY = 'Багаевский район',
  BELOKALITVINSKY = 'Белокалитвинский район',
  BOKOVSKI = 'Боковский район',
  VERHNEDONSKOY = 'Верхнедонской район',
  VESELOVSKY = 'Веселовский район',
  VOLGODONSKOY = 'Волгодонской район',
}

const districtInfo: Record<
  DistrictNameWithoutCoordinates,
  { fiasId: string; geoLatitude: string; geoLongitude: string }
> = {
  [DistrictNameWithoutCoordinates.AZOVSKY]: {
    fiasId: '2232c303-309e-4d51-8090-e5136fb876ac',
    geoLatitude: '46.90033',
    geoLongitude: '39.18564',
  },
  [DistrictNameWithoutCoordinates.AKSAISKY]: {
    fiasId: '7f6fbe97-ebc2-4f09-9aa8-1ecab6adcdba',
    geoLatitude: '47.27005',
    geoLongitude: '39.87204',
  },
  [DistrictNameWithoutCoordinates.BAGAEVSKY]: {
    fiasId: '8e7ec0d6-b9d4-4866-9ad5-ac7f516614ca',
    geoLatitude: '47.26856',
    geoLongitude: '40.37744',
  },
  [DistrictNameWithoutCoordinates.BELOKALITVINSKY]: {
    fiasId: '4c423d13-7115-47a2-b286-e23ac6dfdc46',
    geoLatitude: '48.11678',
    geoLongitude: '40.84982',
  },
  [DistrictNameWithoutCoordinates.BOKOVSKI]: {
    fiasId: '5577b8b6-c8b0-491c-8206-56f13b6e225f',
    geoLatitude: '49.24717',
    geoLongitude: '41.65321',
  },
  [DistrictNameWithoutCoordinates.VERHNEDONSKOY]: {
    fiasId: '257d5b48-0e0d-4c50-8e0b-e9e1b4de9046',
    geoLatitude: '49.77326',
    geoLongitude: '41.28685',
  },
  [DistrictNameWithoutCoordinates.VESELOVSKY]: {
    fiasId: '88167f47-a5ec-4c92-9aa4-9f52f0b612f8',
    geoLatitude: '47.21025',
    geoLongitude: '40.73655',
  },
  [DistrictNameWithoutCoordinates.VOLGODONSKOY]: {
    fiasId: '31b577c3-afe5-4b18-bd5c-2cf68d6c88ec',
    geoLatitude: '47.49983',
    geoLongitude: '41.60949',
  },
}

export const getCoordinatesForAddressesWithoutCoordinates = (
  areaFiasId: string,
) => {
  const districtInfoByFiasId = Object.values(districtInfo).find(
    (district) => district.fiasId === areaFiasId,
  )

  return districtInfoByFiasId
    ? {
        geoLon: districtInfoByFiasId.geoLongitude,
        geoLat: districtInfoByFiasId.geoLatitude,
      }
    : { geoLon: null, geoLat: null }
}
