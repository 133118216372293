import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.grey_200};
  height: 40vh;
  min-height: 340px;

  @media ${(p) => p.theme.breakpoints.desktop} {
    padding: 0 5% 0 5%;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-left: ${getSpacing(3)};
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-left: ${getSpacing(3)};
  }
`
export const STechSup = styled.div`
  padding: ${getSpacing(0.5)};
`

export const SWrapp = styled.div`
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
export const STechSupH3 = styled.h3`
  color: ${(p) => p.theme.colors.grey_600};
  ${defaultTextStyle(8)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    ${defaultTextStyle(9)}
  }
`
export const STechSupLink = styled.a`
  color: ${(p) => p.theme.colors.blue_500};
  ${defaultTextStyle(1)};
  text-decoration: none;
`
export const SUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(p) => p.theme.colors.grey_450};
  ${defaultTextStyle(9)};
  margin-top: ${getSpacing(5)};
  margin-bottom: ${getSpacing(5)};
  gap: ${getSpacing(2)} !important;
`

export const SUserInfoLink = styled.a`
  display: flex;
  cursor: pointer;
`
