const redirectURL = (lastPath?: string) =>
  `${
    process.env.NEXT_PUBLIC_ESIA_LOGIN_URL
  }/esia_login?backend=esia-oauth&next=${
    lastPath || process.env.NEXT_PUBLIC_URL_TO_REDIRECT_AFTER_ESIA
  }`

export const redirectToESIA = (path = '') => {
  const win = window
  if (win) {
    win.location.href = `${redirectURL(win.location.href)}/${path}`
  }
}

export const redirectToGosuslugi = () => {
  const win = window
  if (win) {
    win.location.href = 'https://www.gosuslugi.ru/'
  }
}

export const redirectToOldEdo = () => {
  const win = window
  if (win) {
    win.location.href = 'https://edo.72to.ru/'
  }
}
