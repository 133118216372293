import { useCallback } from 'react'

import { EventFileIcon } from '../../../../../assets/img/logo/EventFile'
import { ProgramFileIcon } from '../../../../../assets/img/logo/ProgramFile'
import { FileItem, STitle, SWrapper } from '../components.styled'

const userGuide = `${process.env.NEXT_PUBLIC_MEDIA_URL}/media/uploads/2020/11/25/________2190-43659462-v2.pdf`
const linkProgramFile = `${process.env.NEXT_PUBLIC_MEDIA_URL}/media/uploads/2019/09/05/aznyck.docx`

export const ForParent = () => {
  const openNewWindowFile = useCallback(() => {
    const win = window.open(userGuide)
    if (win) {
      win.focus()
    }
  }, [])

  return (
    <SWrapper>
      Инструкции для родителей
      <FileItem href={linkProgramFile}>
        <ProgramFileIcon />
        <STitle>Как записаться на программу</STitle>
      </FileItem>
      <FileItem onClick={openNewWindowFile}>
        <EventFileIcon />
        <STitle>Руководство пользователя</STitle>
      </FileItem>
    </SWrapper>
  )
}
