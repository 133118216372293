import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { userAPI } from '../../../axios/axios'
import type { IChildInfo, IEsiaChildInfo } from './children.model'

interface IChildrenReducer {
  initChildren: IChildInfo[] | null
  children: IChildInfo[] | null
  initEsiaChildren: IEsiaChildInfo[] | null
  esiaChildren: IEsiaChildInfo[] | null
}

const initialState: IChildrenReducer = {
  initChildren: null,
  children: null,
  initEsiaChildren: null,
  esiaChildren: null,
}

export const getChildren = createAsyncThunk<IChildInfo[] | null>(
  'children/getChildren',
  async () => {
    try {
      return await userAPI.getChildren()
    } catch {
      return null
    }
  },
)

export const getEsiaChildren = createAsyncThunk<IEsiaChildInfo[] | null>(
  'children/getEsiaChildren',
  async () => {
    try {
      return await userAPI.getChildDataFromESIA()
    } catch {
      return null
    }
  },
)

export const childrenReducer = createSlice({
  name: 'children',
  initialState,
  reducers: {
    setChildren: (state, action: PayloadAction<IChildInfo[]>) => {
      state.children = action.payload
    },
    reset: (state) => {
      state.children = state.initChildren
    },
    setEsiaChildren: (state, action: PayloadAction<IEsiaChildInfo[]>) => {
      state.esiaChildren = action.payload
    },
    resetEsiaChildren: (state) => {
      state.esiaChildren = state.initEsiaChildren
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getChildren.fulfilled,
      (state, action: PayloadAction<IChildInfo[] | null>) => {
        state.children = action.payload
        state.initChildren = action.payload
      },
    )
    builder.addCase(getChildren.rejected, (state) => {
      state.children = null
    })

    builder.addCase(
      getEsiaChildren.fulfilled,
      (state, action: PayloadAction<IEsiaChildInfo[] | null>) => {
        state.esiaChildren = action.payload
        state.initEsiaChildren = action.payload
      },
    )
    builder.addCase(getEsiaChildren.rejected, (state) => {
      state.esiaChildren = null
    })
  },
})

export const { setChildren, reset } = childrenReducer.actions

export default childrenReducer.reducer
