import type { ThemedStyledProps } from 'styled-components'

import type { Theme } from '../../theme'

export const getDefaultBeatifiedScrollbar = (
  props: ThemedStyledProps<any, Theme>,
) => `
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    border: none;
    width: 10px;
    border-radius: ${props.theme.decoration.borderRadius.small};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props.theme.colors.grey_450};
    border-radius: ${props.theme.decoration.borderRadius.small};
  }
`
