import { Modal } from 'components/atoms/Modal'
import { Feedback } from 'home/HomePageContent/MainMenu/components/Feedback/Feedback'
import React, { useCallback, useState } from 'react'

import { generalTheme } from '../../style'
import {
  STechSup,
  STechSupH3,
  STechSupLink,
  SUserInfo,
  SUserInfoLink,
  SWrap,
  SWrapp,
} from './styles/Footer.styled'

export const Footer = () => {
  const [isOpenModalFeedback, setIsOpenModalFeedBack] = useState(false)

  const handleSetOpenModalFeedback = useCallback(() => {
    setIsOpenModalFeedBack(true)
  }, [])

  const handleSetCloseModalFeedback = useCallback(() => {
    setIsOpenModalFeedBack(false)
  }, [])

  return (
    <SWrap>
      <SWrapp>
        <STechSup>
          <STechSupH3>Техническая поддержка</STechSupH3>
          <span>Почта: </span>
          <STechSupLink href="mailto:rmc61@gauro-riacro.ru" target="_blank">
            rmc61@gauro-riacro.ru
          </STechSupLink>
        </STechSup>

        <SUserInfo>
          <SUserInfoLink onClick={handleSetOpenModalFeedback}>
            Обратная связь
          </SUserInfoLink>
          <Modal
            onClose={handleSetCloseModalFeedback}
            onCancel={handleSetCloseModalFeedback}
            open={isOpenModalFeedback}
            modalContentMaxWidth={generalTheme.maxWidthModal.default}>
            <Feedback />
          </Modal>
        </SUserInfo>
      </SWrapp>
    </SWrap>
  )
}
