import { InputNumber } from 'antd'
import { isNil } from 'lodash'
import { forwardRef, useCallback, useState } from 'react'

import type { IInputProps } from '../../model/Input.model'
import { InputResolver } from '../InputResolver'

export interface INumberInputProps extends IInputProps {
  value?: any
  onChange?: (value: string | number) => void
}

// eslint-disable-next-line react/display-name
export const NumberInput = forwardRef<HTMLInputElement, INumberInputProps>(
  ({ value, onBlur, onFocus, onChange, disabled, ...resolverProps }, ref) => {
    const [inputValue, setInputValue] = useState<string | number>()

    const internalValue = typeof value !== 'undefined' ? value : inputValue

    const handleOnChange = useCallback(
      (v: string | number | null) => {
        if (isNil(v)) {
          setInputValue(undefined)
        } else {
          setInputValue(v)
          onChange && onChange(v)
        }
      },
      [onChange],
    )

    return (
      <InputResolver {...resolverProps} disabled={disabled}>
        <InputNumber
          ref={ref}
          disabled={disabled}
          value={internalValue}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleOnChange}
        />
      </InputResolver>
    )
  },
)
