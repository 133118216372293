import { Modal } from 'antd'
import { styleMixins } from 'style'
import { defaultTitleStyle } from 'style/styleMixins/text'
import styled from 'styled-components'

const { getSpacing } = styleMixins.offset
const { getDefaultBeatifiedScrollbar } = styleMixins.other

export const SModal = styled(Modal)<{
  modalContentMaxWidth?: string
  zIndex?: number
}>`
  & .ant-modal-content {
    max-width: ${(p) =>
      p.modalContentMaxWidth ? p.modalContentMaxWidth : '500px'};
    margin: 0 auto;
    font-family: Golos, sans-serif !important;
    width: 100%;
    z-index: ${(p) => (p.zIndex ? p.zIndex : 100)};
    background-color: ${(p) => p.theme.colors.grey_000};
    box-shadow: ${(p) => p.theme.decoration.boxShadow.default};
    border-radius: ${(p) => p.theme.decoration.borderRadius.large};
    padding: ${getSpacing([1.25, 1, 1, 1])};
  }

  & .ant-modal-body {
    max-height: max-content;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  & .ant-modal-close {
    right: 21px;
    top: 15px;
  }

  & .ant-modal-close-x {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.colors.grey_600};

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const SBody = styled.div`
  width: 100%;
  overflow-y: scroll;
  min-height: 330px;
  padding: ${getSpacing(2)};
  color: ${(props) => props.theme.colors.grey_500};
  background-color: ${(props) => props.theme.colors.grey_100};
  border-radius: ${getSpacing([2])};
  order: 2;
  ${getDefaultBeatifiedScrollbar};

  .bodyCard {
    max-width: 350px;
    max-height: 175px !important;
    width: 100%;
  }
`

export const SFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  order: 3;
  ${defaultTitleStyle(2)};
  padding: ${getSpacing([2])};
  color: ${(props) => props.theme.colors.grey_450};
  background-color: ${(props) => props.theme.colors.grey_100};
  border-radius: ${getSpacing([0, 0, 2, 2])};
  margin-top: ${getSpacing(-2)};
`

export const SHeader = styled.div`
  order: 1;
  z-index: 1;
  padding: ${getSpacing([1, 0, 1.5, 2])};
  ${defaultTitleStyle(6)};
  color: ${(props) => props.theme.colors.grey_600};
  background-color: transparent;
`
