import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { userAPI } from '../../../axios/axios'
import type { IDeclaration, IDeclarationData } from './declarations.model'

interface IDeclarationsReducer {
  allDeclaration: IDeclarationData | null
  currentDeclaration: IDeclaration | null
}

const initialState: IDeclarationsReducer = {
  allDeclaration: null,
  currentDeclaration: null,
}

export const getDeclarations = createAsyncThunk<IDeclarationData | null>(
  'declarations/getDeclarations',
  async () => {
    try {
      return await userAPI.getUserDeclarations()
    } catch {
      return null
    }
  },
)

export const declarationsReducer = createSlice({
  name: 'declarations',
  initialState,
  reducers: {
    setCurrentDeclaration: (
      state,
      action: PayloadAction<IDeclaration | null>,
    ) => {
      state.currentDeclaration = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDeclarations.fulfilled,
      (state, action: PayloadAction<IDeclarationData | null>) => {
        state.allDeclaration = action.payload
      },
    )
    builder.addCase(getDeclarations.rejected, (state) => {
      state.allDeclaration = null
    })
  },
})

export const { setCurrentDeclaration } = declarationsReducer.actions

export default declarationsReducer.reducer
