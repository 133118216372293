import classNames from 'classnames'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import type { IButtonProps } from './model/Button.model'
import { ButtonSizeEnum, ButtonStyleEnum } from './model/Button.model'
import { SButton } from './style/Button.styled'

export const Button: FC<IButtonProps> = ({
  disabled,
  size = ButtonSizeEnum.default,
  buttonStyle = ButtonStyleEnum.main,
  onClick,
  children,
  className,
  htmlType,
}) => {
  const onClickEvent = useCallback(
    (e: MouseEvent) => {
      if (onClick) onClick(e)
    },
    [onClick],
  )

  const buttonClassName = useMemo(
    () =>
      classNames(className, {
        disabled,
        [`style--${buttonStyle}`]: buttonStyle,
        [`size--${size}`]: size,
      }),
    [className, disabled, buttonStyle, size],
  )

  return (
    <SButton
      htmlType={htmlType}
      className={buttonClassName}
      onClick={onClickEvent}>
      {children}
    </SButton>
  )
}
