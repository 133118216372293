import Cookies from 'js-cookie'
import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { userAPI } from '../../axios/axios'
import { TokenENUM } from '../../axios/securedAxios'
import { setUser } from '../../store/reducers/user/userReducer'

const IDX_SLICE_TOKEN = 7

export const CatchTokenEsia: FC = () => {
  const dispatch = useDispatch()

  const getMe = useCallback(async () => {
    try {
      const { data } = await userAPI.me()
      dispatch(setUser(data))
    } catch (e) {
      console.error(e)
    }
  }, [dispatch])

  useEffect(() => {
    const accessToken = Cookies.get(TokenENUM.accessToken)
    if (!accessToken) {
      const loc = window.location

      if (loc && loc.hash) {
        const { hash } = loc
        const token = hash.slice(IDX_SLICE_TOKEN)
        Cookies.set(TokenENUM.accessToken, token)
        window.location.hash = ''
        getMe()
      }
    }
  }, [getMe])
  return null
}
