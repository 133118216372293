import type { InputRef } from 'antd'
import { MaskedInput as MaskedInputAntd } from 'antd-mask-input'
import type { ChangeEvent } from 'react'
import { forwardRef, useCallback, useState } from 'react'

import type { IInputProps } from '../../model/Input.model'
import { InputResolver } from '../InputResolver'
import type { IInputResolverProps } from '../InputResolver/InputResolver'

export type onChangeEvent = ChangeEvent<HTMLInputElement> & {
  maskedValue: string
  unmaskedValue: string
}

export interface IMaskInputProps
  extends IInputProps,
    Omit<IInputResolverProps, 'children'> {
  mask?: string | RegExp
  value?: string
  maskedValue?: boolean
  onChange?: (value: string, event: onChangeEvent) => void
  placeholderTop?: boolean
}

// eslint-disable-next-line react/display-name
export const MaskedInput = forwardRef<InputRef, IMaskInputProps>(
  (
    {
      mask = '',
      value,
      onBlur,
      onFocus,
      onChange,
      allowClear,
      disabled,
      maskedValue = true,
      ...resolverProps
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>()
    const internalValue = typeof value !== 'undefined' ? value : inputValue
    const placeholderTop = typeof internalValue !== 'undefined'

    const handleOnChange = useCallback(
      (e: onChangeEvent) => {
        const currentUnmaskedValue = e.unmaskedValue
        const currentMaskedValue = e.maskedValue

        const currentValue = maskedValue
          ? currentMaskedValue
          : currentUnmaskedValue

        setInputValue(currentValue)

        onChange && onChange(currentValue, e)
      },
      [maskedValue, onChange],
    )

    return (
      <InputResolver
        allowClear={allowClear}
        disabled={disabled}
        placeholderTop={placeholderTop}
        {...resolverProps}>
        <MaskedInputAntd
          defaultValue={value}
          ref={ref}
          maskOptions={{
            lazy: true,
          }}
          mask={mask}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          onChange={handleOnChange}
        />
      </InputResolver>
    )
  },
)
