import classNames from 'classnames'
import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import type { IInputProps } from '../../model/Input.model'
import { SPrompt, STitle, SWrap } from './style/InputResolver.styled'

type InputResolverPropsFromInputProps = Pick<
  IInputProps,
  'className' | 'allowClear' | 'error' | 'disabled' | 'prompt' | 'title'
>

export interface IInputResolverProps extends InputResolverPropsFromInputProps {
  children: ReactNode
}

export const InputResolver: FC<IInputResolverProps> = ({
  className,
  error,
  disabled,
  allowClear,
  children,
  title,
  prompt,
}) => {
  const internalClassname = useMemo(
    () =>
      classNames(className, {
        error,
        prompt,
        disabled,
        clear: allowClear,
      }),
    [allowClear, className, disabled, error, prompt],
  )

  const promptContent = useMemo(
    () =>
      prompt ? <SPrompt className={internalClassname}>{prompt}</SPrompt> : null,
    [internalClassname, prompt],
  )

  const titleContent = useMemo(
    () => (title ? <STitle>{title}</STitle> : null),
    [title],
  )

  return (
    <>
      {titleContent}
      {promptContent}
      <SWrap className={internalClassname}>{children}</SWrap>
    </>
  )
}
