import { useRouter } from 'next/router'
import type { FC, ReactNode } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { CloseBurgerIcon } from '../../assets/img/logo/CloseBurger'
import { LinesBurgerIcon } from '../../assets/img/logo/LinesBurger'
import { Button } from '../../components/atoms'
import { HeaderContent } from '../Header/HeaderContent'
import { LocationAddressModal } from '../LocationAddressModal/LocationAddressModal'
import { PagesList } from '../PagesList/PagesList'
import {
  SButton,
  SButtonBurger,
  SHeaderBurger,
  SLocationAddressBurger,
  SWrapHeader,
  SWrapPage,
  SWrapperBurger,
} from './BurgerMenu.styled'
import { RadioGroupFromMobile } from './RadioGroupFromMobile/RadioGroupFromMobile'

export interface IBurgerProps {
  login: ReactNode
}

export const BurgerMenuFromMobile: FC<IBurgerProps> = ({ login }) => {
  const [isActiveBurger, setIsActiveBurger] = useState(true)
  const { pathname } = useRouter()

  useEffect(() => {
    if (pathname) {
      setIsActiveBurger(true)
    }
  }, [pathname])

  const imageForButton = useMemo(
    () => (isActiveBurger ? <LinesBurgerIcon /> : <CloseBurgerIcon />),
    [isActiveBurger],
  )

  const toggleIsActiveBurger = useCallback(() => {
    setIsActiveBurger((prevState) => !prevState)
  }, [])

  return (
    <SButton isActiveBurger={isActiveBurger}>
      <SHeaderBurger>
        <HeaderContent />
      </SHeaderBurger>
      <SWrapHeader />
      <SWrapperBurger>
        <SButtonBurger>
          <Button buttonStyle="default" onClick={toggleIsActiveBurger}>
            {imageForButton}
          </Button>
          <SLocationAddressBurger>
            <LocationAddressModal />
          </SLocationAddressBurger>
          <div>{login}</div>
        </SButtonBurger>
      </SWrapperBurger>
      <SWrapPage>
        <PagesList />
        <RadioGroupFromMobile />
      </SWrapPage>
    </SButton>
  )
}
