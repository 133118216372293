import classNames from 'classnames'
import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { ITextFieldProps } from './model/TextField.model'
import { SLabel, STextArea, SWrap } from './style/TextField.styled'

export const TextField: FC<ITextFieldProps> = ({
  disabled,
  value,
  label,
  className,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState<string | number>()

  useEffect(() => setInputValue(value), [value])

  const customClassName = useMemo(
    () =>
      classNames(className, {
        disabled,
      }),
    [className, disabled],
  )

  const onFocusInput = useCallback(() => {
    if (onFocus) onFocus()
  }, [onFocus])

  const onBlurInput = useCallback(() => {
    if (onBlur) onBlur()
  }, [onBlur])

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.currentTarget.value
      setInputValue(newValue)

      if (onChange) onChange(newValue)
    },
    [onChange],
  )
  return (
    <SWrap>
      {label && <SLabel>{label}</SLabel>}
      <STextArea
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onChange={onChangeInput}
        value={inputValue}
        className={customClassName}
      />
    </SWrap>
  )
}
