import { Button } from 'components/atoms'
import { useVisibility } from 'hooks/useVisibility'
import { useRouter } from 'next/router'
import type { FC, ReactNode } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { pathENUM } from 'registrators/registrators'
import { getUser, setUserLocation } from 'store/reducers/user/userReducer'
import type { RootState } from 'store/store'
import { useAppDispatch } from 'store/store'

import { userAPI } from '../axios/axios'
import { redirectToESIA } from '../axios/utils'
import { BurgerMenuFromMobile } from './BurgerMenu/BurgerMenuFromMobile'
import { Cookie } from './Cookie/Cookie'
import { CatchTokenEsia } from './Esia/Esia'
import { Footer } from './Footer/Footer'
import { HeaderContent } from './Header/HeaderContent'
import { PagesList } from './PagesList/PagesList'
import {
  SFlexWrapp,
  SHeader,
  SLinkUser,
  SLogin,
  SLoginDesktop,
  SNavbarWrapp,
  SNavbarWrapper,
  SPageListWrapp,
  SPagesWrapper,
} from './styles/Navbar.styled'

interface IMainContainerProps {
  children: ReactNode
}

export const MainContainer: FC<IMainContainerProps> = ({ children }) => {
  const { pathname } = useRouter()
  const dispatch = useAppDispatch()
  const visibility = useVisibility()
  const { userLocation, user } = useSelector((state: RootState) => state.user)

  const login = useMemo(() => {
    if (user) {
      return (
        <SLinkUser href={pathENUM.profile}>
          {`${user.person.first_name} ${user.person.last_name}`}
        </SLinkUser>
      )
    }
    return (
      <SLogin>
        <Button buttonStyle="default" onClick={() => redirectToESIA()}>
          Войти
        </Button>
      </SLogin>
    )
  }, [user])

  useEffect(() => {
    if (visibility === 'visible') {
      dispatch(getUser())
    }
  }, [dispatch, visibility])

  const getUserLocation = useCallback(
    async (lat: number, lon: number) => {
      try {
        const res = await userAPI.userLocation(lat, lon)
        const { data } = res.data
        dispatch(setUserLocation(data))
        if (data) {
          window.localStorage.setItem('autoLocation', JSON.stringify(data))
        }
      } catch (e) {
        console.error(e)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        if (pos) {
          const { latitude, longitude } = pos.coords
          getUserLocation(latitude, longitude)
        }
      })
    }
  }, [getUserLocation, userLocation])

  return (
    <>
      <SNavbarWrapp>
        <SNavbarWrapper>
          <SHeader>
            <CatchTokenEsia />
            <HeaderContent />
          </SHeader>
          <SLoginDesktop>{login}</SLoginDesktop>
        </SNavbarWrapper>
        <BurgerMenuFromMobile login={login} />
        <SFlexWrapp>
          <SPageListWrapp>
            <PagesList />
          </SPageListWrapp>
        </SFlexWrapp>
      </SNavbarWrapp>
      <SPagesWrapper>
        {children}
        <Cookie />
      </SPagesWrapper>
      {pathname !== pathENUM.map && <Footer />}
    </>
  )
}
