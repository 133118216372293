import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { HeaderIcon } from '../../assets/img/logo/HeadContent'
import { pathENUM } from '../../registrators/registrators'
import { LocationAddressModal } from '../LocationAddressModal/LocationAddressModal'
import {
  SHeaderContent,
  SLocationAddressHeader,
  SWrap,
} from './HeaderContent.styled'

export const HeaderContent = () => {
  const router = useRouter()

  const handleRedirectToHome = useCallback(() => {
    router.push({
      pathname: pathENUM.home,
    })
  }, [router])

  return (
    <SWrap>
      <HeaderIcon />
      <SHeaderContent onClick={handleRedirectToHome}>
        Навигатор дополнительного образования Ростовской области
      </SHeaderContent>
      <SLocationAddressHeader>
        <LocationAddressModal />
      </SLocationAddressHeader>
    </SWrap>
  )
}
