import styled from 'styled-components'

import { styleMixins } from '../../../../style'

interface IStatisticsProps {
  colorBlock?: string
  textSize?: number
}

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SStatistics = styled.div<IStatisticsProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: ${(p) => p.theme.decoration.borderRadius.smaller};
  color: ${(props) => props.colorBlock || props.theme.colors.grey_500};
  background-color: ${(props) => props.theme.colors.grey_000};
  ${defaultTextStyle(11)};
  padding: ${getSpacing([3])};
  box-shadow: ${(p) => p.theme.decoration.boxShadow.default};

  @media ${(p) => p.theme.breakpoints.desktop} {
    max-width: 352px;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    width: 100%;
  }
`

export const SStatisticsDescription = styled.p`
  ${defaultTextStyle(9)};
  color: ${(props) => props.theme.colors.grey_500};
  margin: 0;
`

export const SStatisticsInfo = styled.p`
  margin: ${getSpacing([0, 0, 1, 0])};
`

export const SIcon = styled.div<IStatisticsProps>`
  fill: ${(props) => props.colorBlock || props.theme.colors.grey_500};
  margin-right: ${getSpacing(4.375)};
`
