import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { FilterTypeENUM } from '../../../components/Filters/0_FilteraCollection/Filters.model'
import type { IPayloadReducer } from './filters.model'

const initialState = {} as Record<FilterTypeENUM, Record<string, any>>

export const filtersReducer = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersAction: (state, action: PayloadAction<IPayloadReducer>) => ({
      ...state,
      [action.payload.key]: {
        ...state[action.payload.key],
        [action.payload.filterKey]: action.payload.item,
      },
    }),
    clearFilters: () => ({} as Record<FilterTypeENUM, Record<string, any>>),
  },
})

export const { setFiltersAction, clearFilters } = filtersReducer.actions

export default filtersReducer.reducer
