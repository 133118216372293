import styled from 'styled-components'

import { styleMixins } from '../../../../style'

const { defaultTitleStyle, defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SWrapper = styled.div`
  ${defaultTitleStyle(6)}
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(2)};
  width: auto;
`

export const FileItem = styled.a`
  display: flex;
  gap: ${getSpacing(2)};
  text-decoration: none;
  align-items: center;
  padding: ${getSpacing(1)};
  border-radius: ${(p) => p.theme.decoration.borderRadius.small};
  background-color: ${(p) => p.theme.colors.grey_000};
  cursor: pointer;
`

export const STitle = styled.p`
  ${defaultTextStyle(9)}
`

export const STitleFeedbackForm = styled.p`
  ${defaultTextStyle(9)};
  color: ${(p) => p.theme.colors.grey_600};
  font-family: Golos, sans-serif;
`

export const SAdviceForm = styled.p`
  ${defaultTextStyle(7)};
  color: ${(p) => p.theme.colors.grey_450};
  font-family: Golos, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SWrapperModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`

export const SOrganisationInfo = styled.div`
  ${defaultTitleStyle(6)};
`

export const SButtonWrapp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const STittleWrap = styled.p`
  ${defaultTitleStyle(3)};
  color: ${(p) => p.theme.colors.grey_600};
  font-family: Golos, sans-serif;
  margin-top: -3%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-top: -5%;
  }
`

export const SWrapp = styled.div`
  width: 100%;
  max-width: 250px;
`
