import type { ComponentProps, FC } from 'react'

import { Button as InternalButton } from './Button'

export interface CompoundedComponent
  extends FC<ComponentProps<typeof InternalButton>> {}

export const Button = InternalButton as CompoundedComponent

export type { IButtonProps } from './model/Button.model'
export { ButtonSizeEnum, ButtonStyleEnum } from './model/Button.model'
