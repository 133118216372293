import React from 'react'

import Icon from '@ant-design/icons'

const EventFile = () => (
  <svg
    width="30"
    height="34"
    viewBox="0 0 30 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.72 4L15.52 8H26V20H19.28L18.48 16H4V4H14.72ZM18 0H0V34H4V20H15.2L16 24H30V4H18.8"
      fill="#0061D9"
    />
  </svg>
)

export const EventFileIcon = (props: any) => (
  <Icon component={EventFile} {...props} />
)
