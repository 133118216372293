export enum pathENUM {
  home = '/',
  programs = '/programs',
  news = '/news',
  events = '/events',
  organizations = '/units',
  talents = '/talents',
  register = '/register',
  map = '/map',
  profile = '/profile',
  instructions = '/instructions',
  documents = '/documents',
}

export const routeTitleMap = {
  [pathENUM.programs]: 'Программы',
  [pathENUM.news]: 'Новости',
  [pathENUM.events]: 'Мероприятия',
  [pathENUM.organizations]: 'Организации',
  [pathENUM.talents]: 'Талантливые дети и молодежь',
}
