import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { userAPI } from '../../../axios/axios'
import type { IUser, IUserCoords, IUserReducer } from './user.model'

const initialState: IUserReducer = {
  user: null,
  userLocation: null,
  userCoords: null,
  userChildFromESIA: null,
  userSelectedChild: null,
  userSelectedApplicantType: '',
  userSelectedApplicantAdulthood: true,
}

export const getUser = createAsyncThunk<IUser | null>(
  'user/getUser',
  async () => {
    try {
      const user = await userAPI.me()
      return user.data
    } catch {
      Cookies.remove('accessToken')
      return null
    }
  },
)

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    logoutReducer: (state, action: PayloadAction<null>) => {
      state.user = action.payload
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload
    },
    setUserChildFromESIA: (state, action) => {
      state.userChildFromESIA = action.payload
    },
    setUserCoords: (state, action: PayloadAction<IUserCoords>) => {
      state.userCoords = action.payload
    },
    setUserSelectedChild: (state, action) => {
      state.userSelectedChild = action.payload
    },
    setUserApplicantType: (state, action) => {
      state.userSelectedApplicantType = action.payload
    },
    setUserSelectedApplicantAdulthood: (state, action) => {
      state.userSelectedApplicantAdulthood = action.payload
    },
    deleteChildren: (state, action) => {
      if (state.user) {
        state.user.children = state.user.children.filter(
          (child) => child.id !== action.payload,
        )
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUser.fulfilled,
      (state, action: PayloadAction<IUser | null>) => {
        state.user = action.payload
      },
    )
    builder.addCase(getUser.rejected, (state) => {
      state.user = null
    })
  },
})

export const {
  setUser,
  logoutReducer,
  setUserLocation,
  setUserCoords,
  setUserChildFromESIA,
  setUserSelectedChild,
  deleteChildren,
  setUserApplicantType,
  setUserSelectedApplicantAdulthood,
} = userReducer.actions

export default userReducer.reducer
