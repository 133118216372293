import { Calendar as AntdCalendar } from 'antd'
import type { CalendarProps } from 'antd/lib/calendar/generateCalendar'
import type { Dayjs } from 'dayjs'
import type { FC } from 'react'

type AntdCalendarProps = Pick<
  CalendarProps<Dayjs>,
  'validRange' | 'dateCellRender' | 'disabledDate' | 'value'
>

export interface ICalendarProps extends AntdCalendarProps {
  className?: string
}

export const Calendar: FC<ICalendarProps> = ({
  validRange,
  dateCellRender,
  disabledDate,
  value,
  className,
}) => (
  <AntdCalendar
    validRange={validRange}
    className={className}
    disabledDate={disabledDate}
    dateCellRender={dateCellRender}
    value={value}
    fullscreen={false}
  />
)
