import type { ThemedStyledProps } from 'styled-components'

import type { Theme } from '../../theme'

export type TextSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

// eslint-disable-next-line consistent-return
export const defaultTextStyle =
  (size: TextSize = 1) =>
  (props: ThemedStyledProps<any, Theme>) => {
    switch (size) {
      case 1: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.normal};
        line-height: ${props.theme.fontSettings.height.normal};
      `
      }
      case 2: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.small};
        line-height: ${props.theme.fontSettings.height.small};
      `
      }
      case 3: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.smaller};
        line-height: ${props.theme.fontSettings.height.smaller};
      `
      }
      case 5: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.small};
        line-height: ${props.theme.fontSettings.height.mini};
      `
      }
      case 6: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.large};
        line-height: ${props.theme.fontSettings.height.normal};
      `
      }
      case 7: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.small};
        line-height: ${props.theme.fontSettings.height.small};
      `
      }
      case 8: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.large};
        line-height: ${props.theme.fontSettings.height.normal};
      `
      }
      case 9: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.normal};
        line-height: ${props.theme.fontSettings.height.small};
      `
      }
      case 10: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.large};
        line-height: ${props.theme.fontSettings.height.large};
      `
      }
      case 11: {
        return `
        font-weight: ${props.theme.fontSettings.weight.high};
        font-size: 29px;
        line-height: 36px;
      `
      }
      case 12: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.small};
        line-height: ${props.theme.fontSettings.height.smaller};
      `
      }
      default:
        return undefined
    }
  }
