import { Button } from 'components/atoms/Button/Button'
import { Modal } from 'components/atoms/Modal'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { COOKIE_TEXT } from 'textConsts'

import { generalTheme } from '../../style'
import { SCookie, SCookieText, STitleCookieWrap } from './styled/Cookie.styled'

export const Cookie = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [modalVis, setModalVis] = useState(false)

  const handleSetCookies = useCallback(() => {
    Cookies.set('CookieAccess', 'cookie_applied')
    setIsVisible((prev) => !prev)
  }, [])

  const handleSetIsVisibleCookie = useCallback(() => {
    Cookies.set('CookieAccess', 'cookie_applied')
    setIsVisible((prev) => !prev)
    setModalVis((prev) => !prev)
  }, [])

  const modal = useMemo(
    () => (
      <Modal
        open={modalVis}
        onClose={() => setModalVis(false)}
        footer={<Button onClick={handleSetIsVisibleCookie}>Принимаю</Button>}
        header="Файлы cookie"
        modalContentMaxWidth={generalTheme.maxWidthModal.default}>
        <STitleCookieWrap>Мы ценим вашу конфиденциальность.</STitleCookieWrap>
        {COOKIE_TEXT}
      </Modal>
    ),
    [handleSetIsVisibleCookie, modalVis],
  )

  useEffect(() => {
    const cookie = Cookies.get('CookieAccess')
    setIsVisible(!cookie)
  }, [])

  return (
    <>
      {isVisible && (
        <SCookie>
          <SCookieText onClick={() => setModalVis(true)}>
            Мы используем файлы cookie
          </SCookieText>
          <Button buttonStyle="cookies" onClick={handleSetCookies}>
            Принимаю
          </Button>
        </SCookie>
      )}
      {modalVis && modal}
    </>
  )
}
