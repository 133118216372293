import type { FC } from 'react'

import {
  SIcon,
  SStatistics,
  SStatisticsDescription,
  SStatisticsInfo,
} from './style/Statistics.styled'

export interface IStatisticsProps {
  body?: string
  colorNow?: string
  children?: any
  icon?: any
}

export const Statistics: FC<IStatisticsProps> = ({
  children,
  colorNow,
  body,
  icon,
}) => (
  <SStatistics colorBlock={colorNow}>
    <SIcon colorBlock={colorNow}>{icon}</SIcon>
    <div>
      <SStatisticsInfo>{children}</SStatisticsInfo>
      <SStatisticsDescription>{body}</SStatisticsDescription>
    </div>
  </SStatistics>
)
