import { Modal } from 'components/atoms/Modal'
import { InstructionsForOrganization } from 'home/HomePageContent/MainMenu/components/ForOrganization/InstructionsForOrganization'
import { ForParent } from 'home/HomePageContent/MainMenu/components/ForParent/ForParent'
import { radioItemENUM } from 'home/HomePageContent/MainMenu/MainMenu.model'
import type { ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { redirectToGosuslugi } from '../../../axios/utils'
import { SButtonGroup, SRadio } from './RadioGroupFromMobile.styled'

type ModalContentType = Record<string, ReactNode>

export const RadioGroupFromMobile = () => {
  const [selectedContent, setSelectedContent] = useState<radioItemENUM>(
    radioItemENUM.feedback,
  )
  const [isOpen, setIsOpen] = useState(false)

  const handleSetOpen = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  const modalContent: ModalContentType = useMemo(
    () => ({
      [radioItemENUM.instructionsForParents]: <ForParent />,
      [radioItemENUM.instructionsForOrganizations]: (
        <InstructionsForOrganization />
      ),
    }),
    [],
  )

  const openModal = useCallback(
    (value: radioItemENUM) => {
      setSelectedContent(value)
      handleSetOpen()
    },
    [handleSetOpen],
  )

  const radioGroup = useMemo(
    () => (
      <SButtonGroup>
        <SRadio onClick={() => redirectToGosuslugi()}>ГОСУСЛУГИ</SRadio>
        <SRadio
          onClick={() => openModal(radioItemENUM.instructionsForOrganizations)}>
          Инструкции для организаций
        </SRadio>
        <SRadio onClick={() => openModal(radioItemENUM.instructionsForParents)}>
          Инструкции для родителей
        </SRadio>
      </SButtonGroup>
    ),
    [openModal],
  )
  return (
    <>
      {radioGroup}
      <Modal onClose={handleSetOpen} onCancel={handleSetOpen} open={isOpen}>
        {modalContent[selectedContent]}
      </Modal>
    </>
  )
}
