import Link from 'next/link'
import styled from 'styled-components'

import { styleMixins } from '../../style'
import { getSpacing } from '../../style/styleMixins/offset'

const { defaultTextStyle } = styleMixins.text

export const SNavbarWrapp = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.blueGray};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`

export const SAdditionalWrapper = styled.div`
  display: flex;
  max-width: 1120px;
  width: 100%;
  @media ${(p) => p.theme.breakpoints.tablet} {
    display: none;
  }
`

export const SAdditionalWrapperMobile = styled.div`
  display: none;
  width: 100%;
  @media ${(p) => p.theme.breakpoints.tablet} {
    display: flex;
    max-width: 1120px;
    width: 100%;
  }
`

export const SNavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1120px;

  @media ${(p) => p.theme.breakpoints.desktop} {
    padding-left: ${getSpacing(3)};
    padding-right: ${getSpacing(3)};
    gap: ${getSpacing(4)};
  }
`

export const SPagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${(p) => p.theme.colors.grey_100};
  overflow-x: hidden;
`

export const SHeader = styled.div`
  display: flex;
  fill: ${(p) => p.theme.colors.grey_000};
  max-width: 1120px;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  background-color: ${(p) => p.theme.colors.blueGray};

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const SLink = styled(Link)`
  color: ${(p) => p.theme.colors.grey_000};
  ${defaultTextStyle(1)};
  text-decoration: none;
  cursor: pointer;
`

export const SLogin = styled.div`
  width: 82px;
  background-color: ${(p) => p.theme.colors.grey_000};
  height: 44px;
  border-radius: ${getSpacing(1.5)};
  display: flex;
  align-items: center;
  align-self: center;

  & .style--textual {
    color: ${(p) => p.theme.colors.grey_000} !important;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-left: ${getSpacing(0.3)};
    justify-content: center;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    height: 35px;
    width: 60px;
  }
`

export const SPageListWrapp = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.blueDefault};
  max-width: 1120px;
  margin: 0 auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: none;
  }
`

export const SFlexWrapp = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(p) => p.theme.colors.blueDefault};
`

export const SLinkUser = styled(Link)`
  color: ${(p) => p.theme.colors.grey_000};
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-right: ${getSpacing(0.5)};
`

export const SLoginDesktop = styled.div`
  display: flex;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: none;
  }
`
