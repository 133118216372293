import type { ThemedStyledProps } from 'styled-components'

import type { Theme } from '../../theme'

export type TitleSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

// eslint-disable-next-line consistent-return
export const defaultTitleStyle =
  (title: TitleSize = 1) =>
  (props: ThemedStyledProps<any, Theme>) => {
    switch (title) {
      case 1: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size:  ${props.theme.fontSettings.size.colossal};
        line-height: ${props.theme.fontSettings.height.huge};
          `
      }
      case 2: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size:  ${props.theme.fontSettings.size.huge};
        line-height: ${props.theme.fontSettings.height.high};
          `
      }
      case 3: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.high};
        line-height: ${props.theme.fontSettings.height.large};
          `
      }
      case 4: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.large};
        line-height: ${props.theme.fontSettings.height.normal};
          `
      }
      case 5: {
        return `
        font-weight: ${props.theme.fontSettings.weight.normal};
        font-size: ${props.theme.fontSettings.size.normal};
        line-height: ${props.theme.fontSettings.height.normal};
          `
      }
      case 6: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.normal};
        line-height: ${props.theme.fontSettings.height.small};
          `
      }
      case 7: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.large};
        line-height: ${props.theme.fontSettings.height.normal};
          `
      }
      case 8: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.normal};
        line-height: ${props.theme.fontSettings.height.normal};
          `
      }
      case 9: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.major};
        line-height: ${props.theme.fontSettings.height.normal};
          `
      }
      case 10: {
        return `
        font-weight: ${props.theme.fontSettings.weight.medium};
        font-size: ${props.theme.fontSettings.size.high};
        line-height: ${props.theme.fontSettings.height.small};
          `
      }
      default:
        return undefined
    }
  }
