import type { FC } from 'react'

import { generalTheme } from '../../../style'
import type {
  INotificationProps,
  TypeNotification,
} from './MessagesNotification.model'
import { SMessagesWrapper } from './MessagesNotification.styled'

const configNotification: Record<
  TypeNotification,
  { color: string; title: string | null }
> = {
  default: { color: generalTheme.colors.grey_600, title: null },
  alert: { color: generalTheme.colors.orange_500, title: 'Предупреждение' },
  danger: { color: generalTheme.colors.red_600, title: 'Ошибка' },
  success: { color: generalTheme.colors.green_600, title: 'Готово!' },
}

export const MessagesNotification: FC<INotificationProps> = ({
  message,
  type,
  title,
  open,
  footer,
  close,
}) => (
  <SMessagesWrapper
    footer={footer ?? null}
    open={open}
    onCancel={close}
    title={configNotification[type].title ?? title}
    colorNotification={configNotification[type].color}>
    {message}
  </SMessagesWrapper>
)
