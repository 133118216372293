import { useCallback, useEffect, useState } from 'react'

export const useVisibility = () => {
  const [visibility, setVisibility] = useState('visible')

  const changeVisibility = useCallback(() => {
    setVisibility(document.visibilityState)
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', changeVisibility)

    return () =>
      document.removeEventListener('visibilitychange', changeVisibility)
  })

  return visibility
}
