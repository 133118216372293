import * as color from './color'
import * as offset from './offset'
import * as other from './other'
import * as text from './text'

export const styleMixins = {
  text,
  offset,
  color,
  other,
}
