import { MaskedInput, NumberInput, TextInput } from './components'

export type CompoundedComponent = typeof TextInput & {
  Number: typeof NumberInput
  Masked: typeof MaskedInput
}

export const Input = TextInput as CompoundedComponent
Input.Number = NumberInput
Input.Masked = MaskedInput
