import type { ComponentProps, FC } from 'react'

import { Text } from './Text'
import { Title } from './Title'

export interface CompoundedComponent extends FC<ComponentProps<typeof Text>> {
  Title: typeof Title
}

export const Typography = Text as CompoundedComponent
Typography.Title = Title
