import { Form } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { Button, Input, TextField } from 'components/atoms'
import { MaskedInput } from 'components/atoms/Input/components'
import { MessagesNotification } from 'components/atoms/MessagesNotification'
import {
  SForm,
  SFromItem,
} from 'components/atoms/SendComment/style/SendComment.styled'
import { UploadFiles } from 'components/atoms/UploadFile'
import { DEFAULT_NOTIFICATION_CLOSE_TIMER } from 'numberConsts'
import { useCallback, useEffect, useState } from 'react'
import { MESSAGE_SUCCESS } from 'textConsts'

import { postFeedback } from '../../../../../axios/axios'
import {
  SAdviceForm,
  SButtonWrapp,
  STitleFeedbackForm,
  STittleWrap,
  SWrapp,
  SWrapperModal,
} from '../components.styled'

export const Feedback = () => {
  const [, setFiles] = useState<UploadFile[]>()
  const onChangeFiles = useCallback((filesData: UploadFile[]) => {
    setFiles(filesData)
  }, [])

  const [isResponseReceived, setIsResponseReceived] = useState<boolean>()
  const [visibleMessage, setVisibleMessage] = useState(false)

  // TODO убрать any
  const postUserFeedback = useCallback(async (data: any) => {
    try {
      const res = await postFeedback.postFeedbackUser(data)
      const { success } = res.data
      setIsResponseReceived(success)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const onFinish = useCallback(
    (data: any) => {
      postUserFeedback(data)
    },
    [postUserFeedback],
  )

  useEffect(() => {
    let timeOut: NodeJS.Timeout
    if (isResponseReceived) {
      setVisibleMessage((prev) => !prev)
      timeOut = setTimeout(() => {
        setVisibleMessage((prev) => !prev)
      }, DEFAULT_NOTIFICATION_CLOSE_TIMER)
    }
    return () => clearTimeout(timeOut)
  }, [isResponseReceived])

  return (
    <SWrapp>
      <STittleWrap>Обратная связь</STittleWrap>
      <SWrapperModal>
        <SForm
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off">
          <SFromItem name="phone" label="Мобильный телефон">
            <MaskedInput mask="+7(000)-000-00-00" />
          </SFromItem>
          <SFromItem name="email" label="Электронная почта">
            <Input />
          </SFromItem>
          <SFromItem name="subject" label="Тема сообщения">
            <Input />
          </SFromItem>
          <SFromItem name="message" label="Текст сообщения">
            <TextField width="200px" />
          </SFromItem>
          <STitleFeedbackForm>Загрузите файлы</STitleFeedbackForm>
          <UploadFiles onChangeCallback={onChangeFiles} />
          <SAdviceForm>Максимальный размер файла - 5 Mb</SAdviceForm>
          <Form.Item>
            <SButtonWrapp>
              <Button htmlType="submit">Отправить</Button>
            </SButtonWrapp>
          </Form.Item>
        </SForm>
      </SWrapperModal>
      <MessagesNotification
        message={MESSAGE_SUCCESS}
        open={visibleMessage}
        title="Уведомление"
        type="success"
      />
    </SWrapp>
  )
}
