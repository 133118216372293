import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appState from './reducers/app/appReducer'
import childrenState from './reducers/children/childrenReducer'
import declarationsState from './reducers/declaration/declarationsReducer'
import filtersState from './reducers/filter/filterReducer'
import registerProgram from './reducers/registerProgramReducer/registerProgramReducer'
import userState from './reducers/user/userReducer'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  user: userState,
  children: childrenState,
  declaration: declarationsState,
  filters: filtersState,
  app: appState,
  registerProgram,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
