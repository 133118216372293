import type { Property } from 'csstype'
import type { ReactNode } from 'react'

export enum ButtonSizeEnum {
  large = 'large',
  default = 'default',
  small = 'small',
}

export enum ButtonStyleEnum {
  main = 'main',
  additional = 'additional',
  textual = 'textual',
  cookies = 'cookies',
  alerted = 'alert',
  profile = 'profile',
  default = 'default',
  exit = 'exit',
  redirect = 'redirect',
  uninteresting = 'uninteresting',
  review = 'review',
}

export type SizeType = keyof typeof ButtonSizeEnum

export type StyleType = keyof typeof ButtonStyleEnum

export interface IButtonProps {
  size?: SizeType
  disabled?: boolean
  buttonStyle?: StyleType
  onClick?: (e: MouseEvent) => void
  fontSize?: Property.FontSize
  width?: Property.Width
  className?: string
  children?: ReactNode
  htmlType?: string
  icon?: ReactNode
}
