import { Upload } from 'antd'
import styled from 'styled-components'

import { styleMixins } from '../../../style'
import { opacityColor } from '../../../style/styleMixins/color'

const { getSpacing } = styleMixins.offset

export const SUploadWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const STextWrapp = styled.p`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-left: ${getSpacing(1)};
  }
`

export const SUpload = styled(Upload)`
  border: 1px solid ${(p) => p.theme.colors.blue_500};
  border-radius: 16px;
  width: 100%;
  & .ant-upload {
    display: flex;
    background-color: transparent;
    color: ${(p) => p.theme.colors.blue_600};
    width: 100%;
    gap: ${getSpacing(1)};
    border-radius: 16px;

    &:hover {
      background: ${(p) => opacityColor(p.theme.colors.blue_500, 0.1)};
    }

    &:active {
      background-color: ${(p) => opacityColor(p.theme.colors.blue_500, 0.2)};
    }

    &.disabled {
      pointer-events: none;
      border-color: ${(p) => p.theme.colors.grey_400};
      color: ${(p) => p.theme.colors.grey_400};
    }

    & .anticon-upload {
      color: ${(p) => p.theme.colors.blue_500};
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 50px;
    }
  }
`
