export const AGREEMENT = 'Согласие на обработку персональных данных'
export const APPLY_PERSONAL_DATA =
  'Согласие на выдачу сертификата дополнительного образования\n' +
  '                (сертификат ДО - реестровая запись о ребенке, созданная в региональном навигаторе в\n' +
  '                целях организации в субъекте РФ персонифицированного учета детей, охваченных услугами\n' +
  '                дополнительного образования» (Приказ Минпросвещения России от 20.05.2021 №262)'
export const FIRST_STEP =
  'Шаг 1. Прими участие в мероприятиях из регионального или российского перечней и стань призером 1, 2 или 3 степени. Перечень мероприятий утверждается ежегодно*.'
export const SECOND_STEP =
  'Шаг 2. Подготовь пакет документов, который включает в себя: анкету, согласие на обработку персональных данных и копию документа,\n' +
  '      подтверждающего полученное достижение (диплом, грамота, сертификат). Анкета должна быть заверена печатью и подписью руководителя образовательной организации.\n' +
  '      Согласие на обработку персональных данных несовершеннолетних участников заполняется родителями или законными представителями.'
export const THIRD_STEP =
  'Шаг 3. Передай весь пакет документов организатору мероприятия или оператору региональной базы данных талантливых детей и молодежи,\n' +
  '      курирующих данное мероприятие (смотри в Региональном перечне мероприятий). И помни, что обновлять свои достижения в РБД необходимо не реже, чем один раз в три года!\n'
export const ADDITIONAL_STEP =
  '* Если ты стал призером статусного мероприятия, но не нашел его в перечнях, то ты можешь предложить данное мероприятие к рассмотрению на экспертном совете по координации работы по выявлению и продвижению талантливых детей и молодежи Ростовской области. Для этого заполни специальную форму заявки на мероприятие и отправь на электронную почту администратору РБД: rbd@pioner72.ru с пометкой «На экспертный совет».'
export const FOURTH_STEP =
  'Региональная база данных талантливых детей и молодежи Ростовской области (РБД) формируется на межведомственной основе из числа талантливых детей и ' +
  'молодежи в возрасте от 5 до 35 лет включительно. Региональная база является одним из механизмов повышения эффективности работы ' +
  'с талантливыми детьми и молодежью в Ростовской области.' +
  'Региональными кураторами РБД являются исполнительные органы государственной власти Ростовской области и образовательные организации ' +
  'высшего образования Ростовской области, осуществляющие функции по выявлению и продвижению талантливых детей и молодежи.'
export const COOKIE_TEXT =
  'Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie\n' +
  '            (пользовательских данных, содержащих сведения о местоположении; тип, язык и версию ОС;\n' +
  '            тип, язык и версию браузера; сайт или рекламный сервис, с которого пришел пользователь;\n' +
  '            тип, язык и разрешение экрана устройства, с которого пользователь обращается к сайту;\n' +
  '            ip-адрес, с которого пользователь обращается к сайту; сведения о взаимодействии пользователя\n' +
  '            с web-интерфейсом и службами сайта) в целях аутентификации пользователя на сайте,\n' +
  '            проведения ретаргетинга, статистических исследований и обзоров.\n' +
  '            Если вы не хотите, чтобы ваши данные обрабатывались, покиньте сайт.'
export const EMPTY_DATA_FROM_GOSUSLUGI = 'Не указано'
export const REQUIRED_MESSAGE = 'Пожалуйста, заполните поле'
export const REQUIRED_FILE_MESSAGE = 'Пожалуйста, загрузите файлы'
export const MESSAGE_SUCCESS = 'Ваше сообщение успешно отправлено!'
export const OPERATION_FAILED =
  'Произошла ошибка, попробуйте выполнить операцию позже.'

export const DEFAULT_APPLICANT_TYPE = '2'
export const DEFAULT_DELEGATE_TYPE = '1'
export const TITLE_TEXT =
  'Навигатор дополнительного образования Ростовской области'
