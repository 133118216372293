import React from 'react'

import Icon from '@ant-design/icons'

const ProgramFile = () => (
  <svg
    width="39"
    height="41"
    viewBox="0 0 39 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 18C30 18 34 22 34 27C34 28.76 33.5 30.42 32.62 31.8L38.78 38L36 40.78L29.76 34.64C28.38 35.5 26.74 36 25 36C20 36 16 32 16 27C16 22 20 18 25 18ZM25 22C23.6739 22 22.4021 22.5268 21.4645 23.4645C20.5268 24.4021 20 25.6739 20 27C20 28.3261 20.5268 29.5979 21.4645 30.5355C22.4021 31.4732 23.6739 32 25 32C26.3261 32 27.5979 31.4732 28.5355 30.5355C29.4732 29.5979 30 28.3261 30 27C30 25.6739 29.4732 24.4021 28.5355 23.4645C27.5979 22.5268 26.3261 22 25 22ZM4 0H32C34.22 0 36 1.78 36 4V20.06C35 18.46 33.62 17.08 32 16V4H4V32H13C13.62 33.5 14.52 34.84 15.62 36H4C1.78 36 0 34.22 0 32V4C0 1.78 1.78 0 4 0ZM8 8H28V12H8V8ZM8 16H18.06C16.46 17 15.08 18.38 14 20H8V16ZM8 24H12.34C12.12 25 12 26 12 27V28H8V24Z"
      fill="#0061D9"
    />
  </svg>
)

export const ProgramFileIcon = (props: any) => (
  <Icon component={ProgramFile} {...props} />
)
