import styled from 'styled-components'

import { styleMixins } from '../../../../style'
import { Typography } from '../../Typography'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SHelpPlaceholder = styled(Typography)`
  padding: ${getSpacing([0.5, 0])};
`

export const SMultipleLabel = styled(Typography)`
  position: absolute;
  top: 28px;
  left: 16px;
  pointer-events: none;
`

export const SPlaceholder = styled(Typography)`
  pointer-events: none;
  user-select: none;
  position: absolute;
  left: ${getSpacing(2)};
  padding-right: ${getSpacing(6)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: ${(p) => p.theme.colors.grey_600};
  top: ${getSpacing(1)};
  transition: all 0.15s linear;
`

export const SWrap = styled.div<{ focused: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-size: 0;
  outline: none;
  & * {
    box-sizing: border-box;
  }

  & .ant-select {
    height: 36px;
    width: 100%;

    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    transition: all ${({ focused }) => (focused ? 0.1 : 0.2)}s linear;
    background: ${(p) => p.theme.colors.grey_000};

    &:hover {
      transition: all 0.1s linear;
    }

    &-status-error {
      border: 1px solid #ff4d4f;
    }

    &-arrow {
      z-index: 1;
      margin-top: 0;
      transition: all 0.2s linear;
      transform: translateY(-30%) ${({ focused }) =>
        focused ? 'rotate(180deg)' : 'rotate(0)'};
      color: ${({ focused, theme }) =>
        focused ? theme.colors.grey_400 : theme.colors.grey_500};
      
      & .anticon {
        pointer-events: none;
      }

      & svg {
        width: 15px;
        height: 15px;
      }
    }

    &-clear {
      z-index: 2;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-30%);
      right: 14px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
    }

    &-selection {
      &-overflow {
        height: 100%;

        &-item {
          display: none;

          &-suffix {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-search {
        display: none;
        height: 100%;
        margin-inline-start: 0;
        width: 100% !important;
      }

      &-placeholder {
        display: none;
      }
    }

    &-selector {
      height: 100%;
      padding: ${getSpacing([0, 5.5, 0, 0])} !important;

      & .ant-select-selection-item {
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 14px);
        white-space: nowrap;
        position: absolute;
        bottom: 6px;
        left: 14px;
        ${defaultTextStyle(2)}
        color: ${(p) => p.theme.colors.grey_600};
      }


    &-dropdown {
      padding: ${getSpacing(1.5)};
      background-color: ${(p) => p.theme.colors.grey_000};
      box-shadow: ${(p) => p.theme.decoration.boxShadow.shadow};
      border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    }

    &-item {
      transition: none;
      box-shadow: none;
      padding: ${getSpacing(1.25)};
      ${defaultTextStyle(3)};
      color: ${(p) => p.theme.colors.grey_500};
      background-color: ${(p) => p.theme.colors.grey_000};
      border-radius: ${(p) => p.theme.decoration.borderRadius.default};
    }
  }

  &.search {
    & .ant-select {
      &-selection {
        &-search-input {
          border: none;
          background: transparent;
          outline: none;
          width: calc(100% - 64px);
          position: absolute;
          left: 14px;
          bottom: 6px;
          padding: 0;
          ${defaultTextStyle(2)}
          color: ${(p) => p.theme.colors.grey_500};
        }

        &-placeholder {
          padding: 0 !important;
          color: ${(p) => p.theme.colors.grey_500};
          ${defaultTextStyle(2)}
          left: 14px !important;
          bottom: 6px;
          right: unset;
          top: unset;
          transform: unset;
        }
      }
    }

    &.focused {
      & .ant-select-selection {
        &-search {
          display: block;
        }

        &-placeholder {
          display: block;
        }
      }
    }
  }

  &.small {
    & .ant-select {
      height: 40px;

      &-selection {
        &-search-input {
          top: 8px;
          left: 8px;
          bottom: unset;
        }

        &-placeholder {
          top: 8px;
          left: 8px !important;
        }
      }

      &-selector {
        & .ant-select-selection-item {
          left: 8px;
          bottom: 8px;
        }
      }

      &-arrow {
        right: 8px;
      }

      &-clear {
        right: 8px;
      }

      &-multiple {
        & .ant-select-selection-search-input {
          top: 5px !important;
        }
      }
    }

    ${SMultipleLabel},
    ${SPlaceholder} {
      top: 10px;
      left: 10px;
    }
  }

  &.selected:not(.disabled) {
    & .ant-select {
      &:hover {
        & .ant-select-arrow {
        }
      }
    }

  }
  
  &.disabled {
    & .ant-select {
      cursor: default;
      background: ${(p) => p.theme.colors.grey_300};
      border-color: transparent;

      &-selector {
        & .ant-select-selection-item {
          color: ${(p) => p.theme.colors.grey_300};
        }
      }

      &-arrow {
        color: ${(p) => p.theme.colors.grey_300};
      }
    }

    & ${SHelpPlaceholder},
    & ${SPlaceholder} {
      color: ${(p) => p.theme.colors.grey_300} !important;
    }
  }
`
