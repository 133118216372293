import { Select as ANTDSelect } from 'antd'
import classNames from 'classnames'
import lodash from 'lodash'
import type { BaseSelectRef } from 'rc-select'
import type { ReactElement, Ref } from 'react'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'

import type {
  ISelectOptionProps,
  ISelectProps,
  MultipleType,
  SelectOnChange,
  SelectValue,
  SelectValueInferMode,
} from './Select.model'
import {
  SHelpPlaceholder,
  SMultipleLabel,
  SPlaceholder,
  SWrap,
} from './style/Select.styled'

export declare type Nullable<T> = T | null

const InternalSelect = <
  Value extends SelectValue,
  Mode extends MultipleType = undefined,
>(
  {
    options = [],
    placeholder = 'Выберите данные',
    searchPlaceholder = 'Поиск данных...',
    className,
    selectClassName,
    label,
    error,
    small,
    disabled,
    showSearch,
    allowClear,
    mode,
    value,
    onSearch,
    onFocus,
    onBlur,
    onChange,
    showArrow,
    defaultValue,
  }: ISelectProps<Mode, Value>,
  ref: Ref<BaseSelectRef>,
) => {
  const selectWrapRef = useRef<Nullable<HTMLDivElement>>(null)

  const [focused, changeFocus] = useState(false)
  const [selectValue, setSelectValue] =
    useState<SelectValueInferMode<Mode, Value>>()
  const [isSearch, changeIsSearch] = useState(false)
  const [, setCountOptionsList] = useState(2)

  const internalSearchPlaceholder = showSearch ? searchPlaceholder : null
  const internalValue = typeof value !== 'undefined' ? value : selectValue
  const isShowPlaceholder =
    !small ||
    (small && !internalValue && !showSearch) ||
    (small && showSearch && !focused && !internalValue)

  const wrapClassName = useMemo(
    () =>
      classNames(className, {
        disabled,
        error,
        focused,
        search: showSearch,
        selected: Array.isArray(internalValue)
          ? !!internalValue.length
          : !!internalValue,
      }),
    [className, disabled, error, focused, showSearch, internalValue],
  )

  const internalSelectClassName = useMemo(
    () =>
      classNames(selectClassName, {
        'ant-select-customize-input': true,
      }),
    [selectClassName],
  )

  const multipleLabel = useMemo(() => {
    if (
      mode === 'multiple' &&
      Array.isArray(internalValue) &&
      internalValue.length > 0 &&
      !isSearch
    ) {
      return (
        <SMultipleLabel>
          Выбрано значений: {internalValue.length}
        </SMultipleLabel>
      )
    }

    return null
  }, [mode, internalValue, isSearch])

  const selectedOptions = useMemo(() => {
    if (Array.isArray(internalValue)) {
      return internalValue
        .map((currentValue) => {
          const option = options.find((el) => el.value === currentValue)

          if (option) {
            return option
          }

          return null
        })
        .filter(Boolean) as ISelectOptionProps<Value>[]
    }

    const findValue = options.find((el) => el.value === internalValue)
    return findValue ? [findValue] : []
  }, [internalValue, options])

  const optionsWithFirst = useMemo(
    () => lodash.without(options, ...selectedOptions),
    [options, selectedOptions],
  )

  const optionsContent = useMemo(
    () => [...selectedOptions, ...optionsWithFirst],
    [optionsWithFirst, selectedOptions],
  )

  const handleOnChange: SelectOnChange<Mode, Value> = useCallback(
    (newValue, option) => {
      onChange && onChange(newValue, option)
      setSelectValue(newValue)
    },
    [onChange],
  )

  const handleOnSearch = useCallback(
    (searchValue: string) => {
      onSearch && onSearch(searchValue)

      if (searchValue) {
        changeIsSearch(true)
      } else {
        changeIsSearch(false)
      }
    },
    [onSearch],
  )

  const onDropdownVisibleChange = useCallback(
    (open: boolean) => {
      changeFocus(open)

      if (open) {
        onFocus && onFocus()
      } else {
        setCountOptionsList(2)
        changeIsSearch(false)
        onBlur && onBlur()
      }
    },
    [onBlur, onFocus],
  )

  return (
    <SWrap className={wrapClassName} ref={selectWrapRef} focused={focused}>
      <ANTDSelect
        ref={ref}
        // @ts-ignore
        onChange={handleOnChange}
        optionFilterProp="label"
        listHeight={216}
        mode={mode}
        defaultValue={defaultValue}
        showArrow={showArrow}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        value={internalValue}
        className={internalSelectClassName}
        placeholder={internalSearchPlaceholder}
        getPopupContainer={() =>
          selectWrapRef.current as NonNullable<HTMLDivElement>
        }
        onDropdownVisibleChange={onDropdownVisibleChange}
        onSearch={showSearch ? handleOnSearch : undefined}
        options={optionsContent}
      />
      {isShowPlaceholder && <SPlaceholder>{placeholder}</SPlaceholder>}
      {label && <SHelpPlaceholder>{label}</SHelpPlaceholder>}
      {multipleLabel}
    </SWrap>
  )
}

export const Select = forwardRef(InternalSelect) as unknown as <
  Value extends SelectValue,
  Mode extends MultipleType = undefined,
>(
  // eslint-disable-next-line no-use-before-define
  props: ISelectProps<Mode, Value> & {
    ref?: Ref<BaseSelectRef>
  },
) => ReactElement
