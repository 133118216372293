import styled from 'styled-components'

import { styleMixins } from '../../style'

const { getSpacing } = styleMixins.offset
const { defaultTitleStyle } = styleMixins.text

export const SWrapPage = styled.div`
  @media ${(p) => p.theme.breakpoints.tablet} {
    z-index: 20;
    flex-direction: column;
    position: fixed;
    top: 126px;
    gap: ${getSpacing(5)};
    width: 100%;

    .SLinkMobile {
      ${defaultTitleStyle(3)};
    }
  }
`

export const SWrapperBurger = styled.div`
  display: flex;
  width: 100%;
  max-width: 1120px;
`

export const SWrapHeader = styled.div`
  @media ${(p) => p.theme.breakpoints.tablet} {
    top: 0;
    left: 0;
    z-index: 20;
    position: fixed;
    background-color: ${(p) => p.theme.colors.blueDefault};
    height: 100vh;
    width: 100vw;
  }
`

export const SButtonBurger = styled.div`
  @media ${(p) => p.theme.breakpoints.tablet} {
    display: flex;
    width: 100vw;
    padding-left: ${getSpacing(2)};
    padding-right: ${getSpacing(2)};
    z-index: 20;
    background-color: ${(p) => p.theme.colors.blueGray};
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    height: 56px;
    top: 64px;
  }
  @media ${(p) => p.theme.breakpoints.tablet} {
    top: auto;
  }
`

export const SHeaderBurger = styled.div`
  @media ${(p) => p.theme.breakpoints.tablet} {
    color: ${(p) => p.theme.colors.grey_000};
    z-index: 30;
    width: 100vw;
    height: 100px;
    padding-left: ${getSpacing(3)};
    padding-right: ${getSpacing(3)};
    ${defaultTitleStyle(6)}
  }
`

export const SLocationAddressBurger = styled.div`
  display: flex;
  max-width: 210px;
  width: 100%;
  margin-left: ${getSpacing(1)};
`

export const SButton = styled.div<{ isActiveBurger: boolean }>`
  display: none;
  position: ${(p) => (p.isActiveBurger ? 'inherit' : 'fixed')};
  flex-direction: column;
  top: 0;
  z-index: 20;

  & .style--textual {
    color: ${(p) => p.theme.colors.grey_000} !important;
  }

  & .style--default {
    z-index: 30;
    border: none !important;
    width: 40px;
    height: 40px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 40px;
    height: 40px;
    display: flex;
    left: 0;

    ${SWrapHeader} {
      display: ${(p) => (p.isActiveBurger ? 'none' : 'flex')};
    }

    ${SHeaderBurger} {
      display: ${(p) => (p.isActiveBurger ? 'none' : 'flex')};
    }

    ${SWrapPage} {
      display: ${(p) => (p.isActiveBurger ? 'none' : 'flex')};
    }
  }
`
