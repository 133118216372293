import { UploadOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload/interface'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import type { IUploadFileModelProps } from './UploadFile.model'
import { STextWrapp, SUpload, SUploadWrapp } from './UploadFile.styled'

const MAX_FILE_SIZE = 5000000

export const UploadFiles: FC<IUploadFileModelProps> = ({
  onChangeCallback,
  errorUpload,
  fileListData,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>(fileListData || [])

  const filteredFileList = useCallback(
    (listFile: UploadFile[]) =>
      !errorUpload
        ? listFile
        : // eslint-disable-next-line array-callback-return,consistent-return
          listFile.filter((item: UploadFile) => {
            if (errorUpload) {
              if (item.size && item.size < MAX_FILE_SIZE) {
                errorUpload(false)
                return item
              }
              errorUpload(true)
              return null
            }
          }),
    [errorUpload],
  )

  const handleOnChangeFile = useCallback(
    (file: UploadChangeParam) => {
      const fileListArray = filteredFileList(file.fileList)
      setFileList(fileListArray)
      onChangeCallback(fileListArray)
    },
    [onChangeCallback, filteredFileList],
  )

  return (
    <SUploadWrapp>
      <SUpload onChange={handleOnChangeFile} fileList={fileList}>
        <STextWrapp>Выберите файлы</STextWrapp>
        <UploadOutlined />
      </SUpload>
    </SUploadWrapp>
  )
}
