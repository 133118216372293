import 'react-dadata/dist/react-dadata.css'

import { Radio } from 'antd'
import styled from 'styled-components'

import { styleMixins } from '../../style'

const { defaultTextStyle, defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const StyledDadataInput = styled.input`
  display: flex;
  width: 100%;
  height: 48px;
  border-radius: ${(p) => p.theme.decoration.borderRadius.small};
  padding-left: ${getSpacing(1)};
  background-color: ${(props) => props.theme.colors.grey_000} !important;
  border: none;

  :hover {
    cursor: pointer;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.grey_300};
    font-weight: ${(props) => props.theme.fontSettings.weight.normal};
  }
`

export const SBodyWrapp = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(4)};
  max-width: 300px;
  padding: ${getSpacing(1)};
  margin-top: ${getSpacing(2)};
  border-radius: ${(p) => p.theme.decoration.borderRadius.default};
`

export const SAnnotationText = styled.p`
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.colors.grey_500};
  ${defaultTitleStyle(5)};
`

export const SMainTitle = styled.p`
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.colors.grey_600};
  ${defaultTextStyle(9)};
`

export const SSecondTitle = styled.p`
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.colors.grey_500};
  ${defaultTextStyle(7)};
  font-family: Golos, sans-serif !important;
`

export const SLocationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
`

export const SRadioGroup = styled(Radio.Group)`
  .ant-space {
    width: 100%;
  }

  .ant-radio-wrapper-checked {
    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    width: 100%;
    padding: ${getSpacing(1.5)};
    border: 2px solid ${(p) => p.theme.colors.blue_500};
    background-color: ${(p) => p.theme.colors.grey_000};
  }
  .ant-radio-wrapper {
    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    width: 100%;
    padding: ${getSpacing(1.5)};
  }
`

export const SLocation = styled.div`
  padding: 0;
  margin: 0;
  color: ${(p) => p.theme.colors.grey_000};
  cursor: pointer;
  ${defaultTextStyle(9)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    display: -webkit-box;
    max-width: 150px;
    width: 100%;
    justify-content: flex-start;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
`
