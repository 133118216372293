import styled from 'styled-components'

import { styleMixins } from '../../../../style'
import type { IStyledTextFieldProps } from '../model/TextField.model'

const { defaultTextStyle } = styleMixins.text
const { opacityColor } = styleMixins.color
const { getSpacing } = styleMixins.offset

export const SLabel = styled.div`
  ${defaultTextStyle(1)};
  color: ${(p) => p.theme.colors.grey_600};
  margin-bottom: ${getSpacing(1)};
`

export const STextArea = styled.textarea<IStyledTextFieldProps>`
  padding: ${getSpacing([1, 1.75, 2, 25])};
  border: none;
  outline: none;
  transition: 0.1s;
  width: 100% !important;
  background-color: ${(p) => p.theme.colors.grey_000};
  border-radius: 8px;
  padding: ${getSpacing([1, 1.75, 2.25])};

  ${defaultTextStyle(1)};

  &:hover {
    box-shadow: 0 0 0 1px ${(p) => p.theme.colors.grey_500};
  }

  &:focus,
  &:focus-visible,
  &:active {
    box-shadow: 0 0 0 1px ${(p) => p.theme.colors.grey_500},
      0 0 0 3px ${(p) => opacityColor(p.theme.colors.blue_500, 0.5)};
  }

  &.disabled {
    pointer-events: none;
    background-color: ${(p) => p.theme.colors.grey_200};
  }
`

export const SWrap = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`
