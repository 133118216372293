import { Form } from 'antd'
import styled from 'styled-components'

import { styleMixins } from '../../../../style'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SWrapp = styled.div`
  display: block;
  width: 100%;

  & .ant-form-item:last-child {
    margin-bottom: 0;
    margin-top: ${getSpacing(3)};
  }
`

export const SButtonWrapp = styled.div`
  display: flex;
  justify-content: center;
`

export const SForm = styled(Form)`
  display: block;
`

export const SFromItem = styled(Form.Item)`
  display: flex;

  & .ant-form-item-row {
    display: block;
    width: 100%;
  }

  & .ant-input {
    border: none;
  }

  & .ant-form-item-label > label {
    ${defaultTextStyle(9)}
    color: ${(p) => p.theme.colors.grey_600};
    font-family: Golos, sans-serif;
  }
`

export const SModal = styled.div`
  margin: ${getSpacing([0, 0, 1.75, 0])};
`
