import { Modal } from 'antd'
import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SMessagesWrapper = styled(Modal)<{ colorNotification: string }>`
  display: flex;
  margin-top: ${getSpacing(35)};
  padding-left: 4.5%;

  & .ant-modal-content {
    min-width: 346px;
    padding: ${getSpacing(3)};
    border-radius: ${(p) => p.theme.decoration.borderRadius.large};

    @media ${(p) => p.theme.breakpoints.mobile} {
      max-width: 200px;
    }
  }

  & .ant-modal-body {
    margin-top: ${getSpacing(2)};
    padding-bottom: ${getSpacing(2)};
    max-width: 298px;
    color: ${(p) => p.colorNotification};
  }

  & .ant-modal-close {
    top: 32px;
  }

  & .ant-modal-header {
    display: flex;
    justify-content: space-between;
    margin-right: ${getSpacing(3)};

    .ant-modal-title {
      ${defaultTitleStyle(3)}
      color: ${(p) => p.colorNotification};
    }
  }
`
