import 'style/globals.css'
import 'nprogress/nprogress.css'

import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { generalTheme } from 'style'
import { ThemeProvider } from 'styled-components'

import MainContainer from '../MainContainer'
import store, { persistor } from '../store/store'

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  useEffect(() => {
    const handleStart = () => {
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConfigProvider locale={ruRU}>
          <ThemeProvider theme={generalTheme}>
            <MainContainer>
              <Component {...pageProps} />
            </MainContainer>
          </ThemeProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
