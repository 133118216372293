import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import Cookies from 'js-cookie'
import { isExpired } from 'react-jwt'

import { redirectToESIA } from './utils'

export enum TokenENUM {
  accessToken = 'accessToken',
}

/** Урлы в которых требуется токен */
const URLsWithToken = [
  'declaration/',
  'comments/new/',
  '/feedback/send/',
  'api/programs/declaration',
  'api/events/event_declarations/',
  'child_info/',
  'esia_children/',
  'child_doc_info/',
]

/** Урлы в которых Content_Type: form-data */
const URLsFormData = ['comments/new/', 'declaration/']

const axiosTemplate = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const nextApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_TO_REDIRECT_AFTER_ESIA,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const fullFilledRequest = (config: AxiosRequestConfig): any => {
  const copyConfig = { ...config }
  const { url } = copyConfig

  if (url && URLsFormData.includes(url)) {
    copyConfig.headers = {
      ...copyConfig.headers,
      'Content-Type': 'multipart/form-data',
    }
  }

  if (url && URLsWithToken.includes(url)) {
    const accessToken = Cookies.get(TokenENUM.accessToken)

    if (accessToken) {
      if (!isExpired(accessToken)) {
        copyConfig.headers = {
          ...copyConfig.headers,
          Authorization: `Token ${accessToken}`,
        }
      } else {
        redirectToESIA()
      }
    } else {
      redirectToESIA()
    }
  }
  return copyConfig
}

const rejectedResponse = (error: any) => Promise.reject(error)

const rejectedRequest = (error: unknown) => Promise.reject(error)

const fullFilledResponse = (res: AxiosResponse) => res

axiosTemplate.interceptors.request.use(fullFilledRequest, rejectedRequest)
axiosTemplate.interceptors.response.use(fullFilledResponse, rejectedResponse)

export default axiosTemplate
