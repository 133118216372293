import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${getSpacing(1)};
  cursor: pointer;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: ${getSpacing([0, 7.5, 0, 3])};
    border-radius: ${getSpacing([0.5, 1.5, 1.5, 1.5])};
    flex-direction: column;
  }
`

export const SRadio = styled.div`
  ${defaultTitleStyle(5)};
  max-width: max-content;
  background-color: ${(p) => p.theme.colors.blueGray};
  color: ${(p) => p.theme.colors.grey_000};
  padding: ${getSpacing([1, 2])};
  border-radius: ${getSpacing([0.5, 1.5, 1.5, 1.5])};
  gap: ${getSpacing(1)};
  display: flex;
`
