import type { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { styleMixins } from '../../../../style'

const { defaultTextStyle } = styleMixins.text
const { opacityColor } = styleMixins.color
const { getSpacing } = styleMixins.offset

export const SButton = styled.button<PropsWithChildren<any>>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSpacing(1.5)};
  border: none;
  background-color: ${(p) => p.theme.colors.blue_500};
  color: ${(p) => p.theme.colors.grey_000};
  cursor: pointer;
  width: 100%;

  &.not-available.disabled {
    background-color: ${(p) => p.theme.colors.red_600};
  }

  &.size--small {
    height: 40px;
    width: 125px;
    ${defaultTextStyle(1)}

    &.style--textual {
      width: 101px;
      height: 24px;
    }
  }

  &.size--large {
    width: 100%;
    height: 52px;
    ${defaultTextStyle(10)}

    &.style--textual {
      width: 100%;
    }
  }

    &.size--default {
     ${defaultTextStyle(1)}

    &.style--textual {
      width: 101px;
      height: 24px;
    }
  }
  ${defaultTextStyle(1)}
  padding: ${getSpacing([1.5, 2.75, 1.5, 2.75])};

  &:focus{
    background-color: ${(p) => p.theme.colors.blue_500};
    box-shadow: 0 0 0 3px ${(p) => opacityColor(p.theme.colors.blue_500, 0.5)};
  }
  
  &:hover{
    background-color: ${(p) => p.theme.colors.blue_600};
    cursor: pointer;
  }
 
  &:active{
    background-color: ${(p) => p.theme.colors.blue_700};
  }

  &.disabled {
    pointer-events: none;
    background-color: ${(p) => p.theme.colors.grey_400};
    color: ${(p) => p.theme.colors.grey_000};
  }
  
  &.style--additional {
    border: 1px solid ${(p) => p.theme.colors.blue_500};
    background-color: transparent;
    color: ${(p) => p.theme.colors.blue_600};
    margin-bottom: ${getSpacing(3)};

    &:focus {
      border: 0 0 0 3px ${(p) => opacityColor(p.theme.colors.blue_500, 0.5)},
    }

    &:hover {
      background: ${(p) => opacityColor(p.theme.colors.blue_500, 0.1)};
    }

    &:active {
      background-color: ${(p) => opacityColor(p.theme.colors.blue_500, 0.2)};
    }

    &.disabled {
      pointer-events: none;
      border-color: ${(p) => p.theme.colors.grey_400};
      color: ${(p) => p.theme.colors.grey_400};
    }
  }

  &.style--cookies{
    height: 32px;
    width: 72px;
    ${defaultTextStyle(7)}
    color: ${(p) => p.theme.colors.grey_000};
    background-color: ${(p) => p.theme.colors.blueGray};
    border-radius: ${getSpacing(6)};
    margin-right: ${getSpacing(0.75)};
  }

  &.style--alerted{
    border: 1px solid ${(p) => p.theme.colors.red_500};
    background-color: transparent;
    color: ${(p) => p.theme.colors.red_500};
    width: 100%;
    margin: ${getSpacing([2, 0])};

    &:focus {
      border: 0 0 0 3px ${(p) => opacityColor(p.theme.colors.red_500, 0.5)},
    }

    &:hover {
      background: ${(p) => opacityColor(p.theme.colors.red_500, 0.1)};
    }

    &:active {
      background-color: ${(p) => opacityColor(p.theme.colors.red_500, 0.2)};
    }

    &.disabled {
      pointer-events: none;
      border-color: ${(p) => p.theme.colors.grey_400};
      color: ${(p) => p.theme.colors.grey_400};
    }
  }
  
  &.style--exit{
    background-color: transparent;
    color: ${(p) => p.theme.colors.red_500};
    width: 300px;
    border: 1px solid ${(p) => p.theme.colors.red_500};


    @media ${(p) => p.theme.breakpoints.mobile} {
      width: 200px;
    }

    &:focus {
      border: 0 0 0 3px ${(p) => opacityColor(p.theme.colors.red_500, 0.5)},
    }

    &:hover {
      background: ${(p) => opacityColor(p.theme.colors.red_500, 0.1)};
    }

    &:active {
      background-color: ${(p) => opacityColor(p.theme.colors.red_500, 0.2)};
    }

    &.disabled {
      pointer-events: none;
      border-color: ${(p) => p.theme.colors.grey_400};
      color: ${(p) => p.theme.colors.grey_400};
    }
  }
  
  &.style--profile{
    height: auto;
    width: 352px;
    ${defaultTextStyle(10)}
    color: ${(p) => p.theme.colors.blue_600};
    text-decoration-line: none; !important;
    background-color: transparent;
    border: 1px solid ${(p) => p.theme.colors.blue_600};
    
    padding: ${getSpacing(1.5)};

    &:hover {
      background: ${(p) => opacityColor(p.theme.colors.blue_700, 0.1)};
      transition: 0.5s;
    }
    &:active {
      background-color: ${(p) => opacityColor(p.theme.colors.blue_700, 0.2)};
    }
  }
  
  &.style--textual {
    background: none;
    border: none;
    box-shadow: none;
    color: ${(p) => p.theme.colors.blue_600};
    padding-right: 0;

    &:focus {
      border: 3px solid ${(p) => opacityColor(p.theme.colors.blue_500, 0.54)};
      border-radius: 4px;
    }
    
    &:hover {
      text-decoration: underline ${(p) => p.theme.colors.blue_500};
    }
    
    &:active {
      border:none
    }

    &.disabled {
      pointer-events: none;
      background-color: ${(p) => p.theme.colors.grey_000};
      color: ${(p) => p.theme.colors.grey_400};
    }
  }
  
  &.style--default {
    background: none;
    color: ${(p) => p.theme.colors.grey_600};
    ${defaultTextStyle(1)};
    
    &:focus {
      box-shadow: none;
    }
    
    &:focus-visible {
      outline: none;
    }
  }
  
  &.style--redirect{
    display: flex;
    border: 2px solid ${(p) => p.theme.colors.grey_000};
    background-color: transparent;
    ${defaultTextStyle(1)};
    font-family: Golos, sans-serif !important;
    height: 50px;
    width: 300px;
    padding: 0;
    
    &:focus {
      box-shadow: none;
    }
    
    @media ${(p) => p.theme.breakpoints.tablet} {
      width: 200px !important;
    }

    @media ${(p) => p.theme.breakpoints.mobile} {
      width: 150px !important;
      ${defaultTextStyle(2)};
    }
  }
  
  &.style--uninteresting{
    display: flex;
    background: none;
    color: ${(p) => p.theme.colors.grey_000};
    ${defaultTextStyle(1)};
    height: 50px;
    width: max-content;
    padding: 0;
    font-family: Golos, sans-serif !important;
    
    &:focus {
      box-shadow: none;
    }

    @media ${(p) => p.theme.breakpoints.mobile} {
      padding-left: ${getSpacing(2)};
      width: 100px;
      ${defaultTextStyle(1)};
    }
  }

  &.style--review{
    display: flex;
    background-color: ${(p) => p.theme.colors.grey_000};
    color: ${(p) => p.theme.colors.grey_600};
    height: 50px;
    width: 200px;
    padding: 0;
    ${defaultTextStyle(1)};
    font-family: Golos, sans-serif !important;
    
    &:focus {
      box-shadow: none;
    }

    @media ${(p) => p.theme.breakpoints.mobile} {
      width: 125px;
      ${defaultTextStyle(2)};
    }
  }
`
