import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SCookie = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: ${(p) => p.theme.colors.grey_000};
  width: auto;
  height: 48px;
  background-color: #2b3249;
  box-shadow: 15px 15px -10px;
  border-radius: 48px;
  bottom: 25px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: fit-content;
    ${defaultTextStyle(2)}
  }

  @media (max-width: 401px) {
    width: 300px;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    width: 80%;
    ${defaultTextStyle(2)}
  }
`

export const SCookieText = styled.p`
  text-decoration-line: underline;
  cursor: pointer;
  margin: ${getSpacing(1)};

  @media ${(p) => p.theme.breakpoints.tablet} {
    ${defaultTextStyle(2)}
  }
`

export const STitleCookieWrap = styled.p`
  ${defaultTextStyle(9)};
  color: black;
`
