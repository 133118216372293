import styled from 'styled-components'

import { styleMixins } from '../../../../../../style'
import { opacityColor } from '../../../../../../style/styleMixins/color'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const STitle = styled.p`
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.grey_600};
`

export const SPrompt = styled.p`
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.grey_450};
`

export const SWrap = styled.div`
  position: relative;

  & .ant-input-number,
  & .ant-input {
    border: none;
    outline: none;
    overflow: hidden;
    width: 100%;
    height: 36px;
    transition: all 0.1s linear;
    border-radius: ${(p) => p.theme.decoration.borderRadius.small} !important;
    background-color: ${(p) => p.theme.colors.grey_000};
    ${defaultTextStyle(1)};
    padding: ${getSpacing([0.75, 1.75, 0.75])};

    &:hover {
      border: 1px solid ${(p) => p.theme.colors.grey_500};
    }

    &:focus {
      outline: 2px solid ${(p) => opacityColor(p.theme.colors.blue_600, 0.5)};
      transition: all 0.1s linear;
    }

    &-affix-wrapper {
      padding: 0;
      height: 38px;

      input {
        outline: none !important;

        &:hover {
          border: 0;
        }
      }

      &:hover {
        border: 1px solid ${(p) => p.theme.colors.grey_500};
        transition: all 0.1s linear;
      }
    }

    &-affix-wrapper-focused {
      outline: 2px solid ${(p) => opacityColor(p.theme.colors.blue_500, 1)};
      transition: all 0.1s linear;
    }


    &-suffix {
      margin: ${getSpacing(2)};
    }

    &-prefix {
      padding: 0 8px 0 8px;
    }
  }

  &.disabled {
    & .ant-input-number,
    & .ant-input {
      background-color: ${({ theme }) => theme.colors.grey_200};
      border-color: ${(p) => p.theme.colors.grey_200};
      pointer-events: none;
    }
  }


  & .ant-input-number {
    padding: 0 !important;


    &-focused {
      outline: 2px solid ${(p) => opacityColor(p.theme.colors.blue_600, 0.5)};
      transition: all 0.1s linear;
    }

    &-input {
      height: 100%;

      &-wrap {
        height: 100%;
      }
    }
  }

  & .ant-input-number-handler-wrap {
    visibility: collapse;
  }

  &.error {
    & .ant-input-number,
    & .ant-input {
      border-color: ${(p) => p.theme.colors.red_500};
    }
  }
}

`
