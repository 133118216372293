import type { IInstructionsData } from 'api/interfaces/instructions/interfaces.model'
import { ProgramFileIcon } from 'assets/img/logo/ProgramFile'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { pathENUM } from 'registrators/registrators'

import { pagesDataAPI } from '../../../../../axios/axios'
import { SLinkInstructions } from '../../MainMenu.styled'
import { SOrganisationInfo, STitle } from '../components.styled'

export const InstructionsForOrganization = () => {
  const [instructionData, setInstructionData] = useState<IInstructionsData[]>(
    [],
  )

  const getInstructions = useCallback(async () => {
    try {
      const instructions = await pagesDataAPI.getInstructions()
      const { data } = instructions.data
      setInstructionData(data)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    getInstructions()
  }, [getInstructions])

  const instruction = useMemo(
    () =>
      instructionData?.map((instructions) => (
        <SLinkInstructions
          key={instructions.id}
          href={`${pathENUM.instructions}/${instructions.id}`}>
          <ProgramFileIcon />
          <STitle>{instructions.title}</STitle>
        </SLinkInstructions>
      )),
    [instructionData],
  )
  return (
    <SOrganisationInfo>
      Инструкции для организаций{instruction}
    </SOrganisationInfo>
  )
}
