import styled from 'styled-components'

import { styleMixins } from '../../style'

const { getSpacing } = styleMixins.offset
const { defaultTextStyle } = styleMixins.text

export const SHeaderContent = styled.div`
  height: 100%;
  display: flex;
  color: ${(p) => p.theme.colors.grey_000};
  background-color: ${(p) => p.theme.colors.blueGray};
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  ${defaultTextStyle(6)};

  @media ${(p) => p.theme.breakpoints.tablet} {
    ${defaultTextStyle(9)};
    overflow: hidden;
    height: 64px;
    background-color: transparent;
  }

  @media (max-width: 324px) {
    ${defaultTextStyle(2)};
  }
`

export const SLocationAddressHeader = styled.div`
  display: flex;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: none;
  }
`

export const SWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: ${getSpacing(2)};
`

export const SHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSpacing(1)};
  color: ${(p) => p.theme.colors.grey_000};
  text-decoration: none;
  margin: 0;
  padding: 0;
`
