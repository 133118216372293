import type { RadioChangeEvent } from 'antd'
import { Radio, Space } from 'antd'
import { Button } from 'components/atoms/Button/Button'
import { Modal } from 'components/atoms/Modal'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { AddressSuggestions } from 'react-dadata'
import { useDispatch, useSelector } from 'react-redux'
import { setUserCoords, setUserLocation } from 'store/reducers/user/userReducer'
import type { RootState } from 'store/store'
import { useLocalStorage } from 'usehooks-ts'

import { userAPI } from '../../axios/axios'
import { generalTheme } from '../../style'
import { getCoordinatesForAddressesWithoutCoordinates } from './getCoordinates'
import {
  SAnnotationText,
  SBodyWrapp,
  SLocation,
  SLocationBlock,
  SMainTitle,
  SRadioGroup,
  SSecondTitle,
  StyledDadataInput,
} from './Modal.styled'

const DADATA_TOKEN = 'd0f1dba8edb62a9900ac190e3a50e3da54654b52'
const MY_LOCATION_PLACEHOLDER = 'Моё местоположение'
const ANNOTATION =
  'После выбора местоположения, вы увидите программы и мероприятия, доступные для вас'
enum RadioValueENUM {
  automatically = 'automatically',
  manual = 'manual',
}

interface ICoordinates {
  geoLongitude?: number
  geoLatitude?: number
}

interface ILocation {
  id?: string
  name: string
  phone?: string
  email?: string
  site?: string
  GUID?: string
  unit_public_name?: string
}

export const LocationAddressModal = () => {
  const dispatch = useDispatch()
  const addressSuggestionRef = useRef(null)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [radioValue, setRadioValue] = useState<RadioValueENUM>(
    RadioValueENUM.automatically,
  )

  const [locationChosenByUser, setLocationChosenByUser] =
    useLocalStorage<ILocation | null>('location', null)
  const [autoLocation] = useLocalStorage<ILocation | null>('autoLocation', null)

  const autoSetFirstRadioValue = useCallback(() => {
    if (
      locationChosenByUser?.name === autoLocation?.name ||
      locationChosenByUser == null
    ) {
      setRadioValue(RadioValueENUM.automatically)
    } else {
      setRadioValue(RadioValueENUM.manual)
    }
  }, [autoLocation, locationChosenByUser])

  const [coordinatesChosenByUser, setCoordinatesChosenByUser] =
    useState<ICoordinates>({})

  const { userLocation } = useSelector((state: RootState) => state.user)
  const [valueLocation, setValueLocation] = useState(MY_LOCATION_PLACEHOLDER)
  const valueCityForInput = useMemo(
    () => (valueLocation !== MY_LOCATION_PLACEHOLDER ? valueLocation : ''),
    [valueLocation],
  )
  const defaultQuery = `${process.env.NEXT_PUBLIC_REGION_LOCATION}, ${valueCityForInput}`

  useEffect(() => {
    switch (radioValue) {
      case RadioValueENUM.automatically: {
        if (userLocation?.name && !locationChosenByUser) {
          setValueLocation(userLocation.name)
          setLocationChosenByUser(userLocation)
        }
        if (userLocation?.name && locationChosenByUser?.name) {
          setValueLocation(locationChosenByUser.name)
        }
        if (!userLocation?.name) setValueLocation(MY_LOCATION_PLACEHOLDER)
        break
      }
      case RadioValueENUM.manual: {
        if (locationChosenByUser) {
          setValueLocation(locationChosenByUser.name)
        } else setValueLocation(MY_LOCATION_PLACEHOLDER)
        break
      }
      default: {
        setValueLocation(MY_LOCATION_PLACEHOLDER)
        break
      }
    }
  }, [locationChosenByUser, radioValue, setLocationChosenByUser, userLocation])

  const getUserLocation = useCallback(
    async (lat: number, lon: number) => {
      try {
        const res = await userAPI.userLocation(lat, lon)
        const { data } = res.data
        dispatch(setUserLocation(data))
      } catch (e) {
        console.error(e)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    autoSetFirstRadioValue()
    // Вызов данной функции осуществляется только на mount компоненты
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (radioValue === RadioValueENUM.automatically) {
      navigator.geolocation.getCurrentPosition((pos) => {
        if (pos) {
          const { latitude, longitude } = pos.coords
          setCoordinatesChosenByUser({
            geoLatitude: latitude,
            geoLongitude: longitude,
          })
        }
      })
    }
  }, [getUserLocation, radioValue])

  const handleSetOpenModal = useCallback(() => {
    setIsVisibleModal(true)
  }, [])

  const handleSetCloseModal = useCallback(() => {
    setIsVisibleModal(false)
  }, [])

  const changeRadioValue = useCallback((e: RadioChangeEvent) => {
    const { value } = e.target
    setRadioValue(value)
  }, [])

  const setUserPosition = useCallback(
    async (lat: number, lon: number) => {
      try {
        const res = await userAPI.userLocation(lat, lon)
        const { data } = res.data
        dispatch(setUserLocation(data))
        dispatch(setUserCoords({ lat, lon }))
        setLocationChosenByUser(data)
        setValueLocation(data.name)
      } catch (e) {
        console.error(e)
      }
    },
    [dispatch, setLocationChosenByUser],
  )

  const handleOnChange = useCallback(
    (dadata: DaDataSuggestion<DaDataAddress> | undefined) => {
      if (dadata) {
        let geoLon = dadata.data.geo_lon
        let geoLat = dadata.data.geo_lat

        if ((!geoLon || !geoLat) && !!dadata.data.area_fias_id) {
          const coordinates = getCoordinatesForAddressesWithoutCoordinates(
            dadata.data.area_fias_id,
          )
          geoLon = coordinates.geoLon
          geoLat = coordinates.geoLat
        }

        const parsedGeoLon = parseFloat(geoLon ?? '')
        const parsedGeoLat = parseFloat(geoLat ?? '')
        if (!Number.isNaN(geoLon) && !Number.isNaN(geoLat)) {
          setCoordinatesChosenByUser({
            geoLatitude: parsedGeoLat,
            geoLongitude: parsedGeoLon,
          })
        }
      }
    },
    [],
  )

  const approveSetUserPosition = useCallback(() => {
    const { geoLongitude, geoLatitude } = coordinatesChosenByUser
    if (geoLatitude && geoLongitude) {
      setUserPosition(geoLatitude, geoLongitude).then(handleSetCloseModal)
    }
  }, [coordinatesChosenByUser, handleSetCloseModal, setUserPosition])

  const suggestionInput = useMemo(
    () => (
      <AddressSuggestions
        ref={addressSuggestionRef}
        token={DADATA_TOKEN}
        defaultQuery={defaultQuery}
        onChange={handleOnChange}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        customInput={(props: any) => (
          <StyledDadataInput
            placeholder="Поиск"
            disabled={radioValue !== RadioValueENUM.manual}
            {...props}
          />
        )}
      />
    ),
    [defaultQuery, handleOnChange, radioValue],
  )

  const modal = useMemo(() => {
    if (isVisibleModal) {
      return (
        <Modal
          open={isVisibleModal}
          onClose={handleSetCloseModal}
          modalContentMaxWidth={generalTheme.maxWidthModal.default}>
          <SMainTitle>Где Вы находитесь?</SMainTitle>
          <SBodyWrapp>
            <SAnnotationText>{ANNOTATION}</SAnnotationText>
            <SLocationBlock>
              <SMainTitle>Текущее местоположение</SMainTitle>
              <SSecondTitle>{valueCityForInput}</SSecondTitle>
            </SLocationBlock>
            <SLocationBlock>
              <SMainTitle>Выбор региона</SMainTitle>
              <SRadioGroup onChange={changeRadioValue} value={radioValue}>
                <Space direction="vertical">
                  <Radio value={RadioValueENUM.automatically}>
                    Автоматически
                  </Radio>
                  <Radio value={RadioValueENUM.manual}>Вручную</Radio>
                </Space>
              </SRadioGroup>
              {suggestionInput}
            </SLocationBlock>
            <Button onClick={approveSetUserPosition}>Применить</Button>
          </SBodyWrapp>
        </Modal>
      )
    }
    return null
  }, [
    isVisibleModal,
    handleSetCloseModal,
    valueCityForInput,
    changeRadioValue,
    radioValue,
    suggestionInput,
    approveSetUserPosition,
  ])

  return (
    <>
      <SLocation onClick={handleSetOpenModal}>{valueLocation}</SLocation>
      {modal}
    </>
  )
}
