import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { AppStateModel } from './app.model'

const initialState: AppStateModel = {
  fetch: false,
}

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = action.payload
    },
  },
})

export const { setFetch } = appReducer.actions

export default appReducer.reducer
