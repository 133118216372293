const size = {
  mobile: '393px',
  tablet: '834px',
  desktop: '1191px',
}

export const generalTheme = {
  breakpoints: {
    mobile: `(max-width: ${size.mobile})`,
    desktop: `(max-width: ${size.desktop})`,
    tablet: `(max-width: ${size.tablet})`,
  },
  fontSettings: {
    weight: {
      normal: '400',
      medium: '500',
      high: '700',
    },
    size: {
      colossal: '44px',
      huge: '30px',
      high: '22px',
      major: '19px',
      large: '18px',
      normal: '16px',
      small: '14px',
      smaller: '12px',
    },
    height: {
      huge: '60px',
      high: '36px',
      large: '28px',
      normal: '24px',
      small: '20px',
      smaller: '19px',
      mini: '16px',
    },
  },
  spacing: {
    default: 8,
  },
  maxWidthModal: {
    default: '305px',
    high: '500px',
  },
  decoration: {
    boxShadow: {
      default: '0 0 15px -15px #000000',
      shadow: '0px 15px 15px -10px #EFF0F2',
      shadow_hover: '0px 15px 20px -10px #E5E5EB',
    },
    borderRadius: {
      large: '32px',
      default: '16px',
      smaller: '12px',
      small: '8px',
    },
    borderRadiusTop: {
      large: '32px 32px 0 0',
      default: '16px 16px 0 0',
      smaller: '12px 12px 0 0',
      small: '8px 8px 0 0',
    },
    borderRadiusBottom: {
      large: '0 0 32px 32px',
      default: '0 0 16px 16px',
      smaller: '0 0 12px 12px',
      small: '0 0 8px 8px',
    },
  },
  colors: {
    grey_600: '#000000',
    grey_500: '#333333',
    grey_450: '#76767A',
    grey_400: '#A7A8AB',
    grey_300: '#D9D9DE',
    grey_200: '#E5E5EB',
    grey_100: '#F5F5F7',
    grey_000: '#FFFFFF',
    blue_700: '#004AA6',
    blue_600: '#0050B2',
    blue_500: '#0061D9',
    red_600: '#D51A1A',
    red_500: '#E52E2E',
    red_opacity: 'rgba(229, 46, 46, 0.1)',
    blue_opacity: 'rgba(0, 97, 217, 0.75)',
    orange_500: '#FF9500',
    green_600: '#348C38',
    green_500: '#43A047',
    blueGray: '#3B4256',
    blueDefault: '#2B3249',
    lightBackGround: '#DAE0E8',
  },
} as const

export type Theme = typeof generalTheme
