import Link from 'next/link'
import styled from 'styled-components'

import { styleMixins } from '../../style'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SLink = styled(Link)`
  color: ${(p) => p.theme.colors.grey_000};
  ${defaultTextStyle(1)};
  text-decoration: none;
  cursor: pointer;

  @media ${(p) => p.theme.breakpoints.mobile} {
    ${defaultTextStyle(2)};
  }
`

export const LinkWrapp = styled.div`
  display: flex;
  align-items: center;
  max-width: 1120px;
`

export const LinkContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  padding: ${getSpacing([2, 0])};
  gap: ${getSpacing(3.75)};

  @media ${(p) => p.theme.breakpoints.desktop} {
    padding-left: ${getSpacing(3)};
    padding-right: ${getSpacing(3)};
  }

  @media (max-width: 422px) {
    max-width: 350px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex-direction: column;
  }
`
