import { Form, Select } from 'antd'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'

import { generalTheme } from '../../../style'
import { Button } from '../Button'
import { Input } from '../Input'
import { Modal } from '../Modal'
import { TextField } from '../TextField'
import {
  SButtonWrapp,
  SForm,
  SFromItem,
  SModal,
  SWrapp,
} from './style/SendComment.styled'

export interface ICommentFields {
  theme: string
  message_text: string
  rate: number
}

export interface ISendCommentProps {
  callbackData: (values: ICommentFields) => void
}

export const SendComment: FC<ISendCommentProps> = ({ callbackData }) => {
  const [visibility, setVisibility] = useState(false)
  const onHandleClick = () => {
    setVisibility((prev) => !prev)
  }

  const onFinish = useCallback(
    (values: ICommentFields) => {
      callbackData(values)
    },
    [callbackData],
  )

  return (
    <>
      <Button buttonStyle="additional" onClick={onHandleClick}>
        Написать отзыв
      </Button>
      {visibility && (
        <Modal
          modalContentMaxWidth={generalTheme.maxWidthModal.default}
          header={<SModal>Написать отзыв</SModal>}
          open
          onClose={onHandleClick}>
          <SWrapp>
            <SForm
              name="basic"
              layout="vertical"
              onFinish={onFinish as any}
              autoComplete="off">
              <SFromItem label="Тема" name="theme">
                <Input />
              </SFromItem>
              <SFromItem label="Текст сообщения" name="message_text">
                <TextField />
              </SFromItem>
              <SFromItem label="Поставить оценку" name="rate">
                <Select>
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
                </Select>
              </SFromItem>
              <Form.Item>
                <SButtonWrapp>
                  <Button htmlType="submit">Отправить</Button>
                </SButtonWrapp>
              </Form.Item>
            </SForm>
          </SWrapp>
        </Modal>
      )}
    </>
  )
}
