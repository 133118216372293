import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type {
  ISetEduPaymentPayload,
  IState,
} from './registerProgramReducer.model'

const initialState = {} as IState

export const registerProgramReducer = createSlice({
  name: 'registerProgram',
  initialState,
  reducers: {
    setEduPayment: (state, action: PayloadAction<ISetEduPaymentPayload>) => {
      state.paymentEdu = action.payload
    },
  },
})

export const { setEduPayment } = registerProgramReducer.actions

export default registerProgramReducer.reducer
