import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent } from 'react'
import { forwardRef, useCallback, useState } from 'react'

import type { IInputProps } from '../../model/Input.model'
import { InputResolver } from '../InputResolver'

export interface ITextInputProps extends IInputProps {
  value?: string
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  prefix?: any
  suffix?: any
  placeholder?: string
  defaultValue?: string
}

// eslint-disable-next-line react/display-name
export const TextInput = forwardRef<InputRef, ITextInputProps>(
  (
    {
      value,
      onChange,
      disabled,
      prefix,
      suffix,
      placeholder,
      defaultValue,
      ...resolverProps
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>()

    const internalValue = typeof value !== 'undefined' ? value : inputValue

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value

        setInputValue(currentValue)

        onChange && onChange(currentValue, e)
      },
      [onChange],
    )

    return (
      <InputResolver {...resolverProps} disabled={disabled}>
        <Input
          ref={ref}
          disabled={disabled}
          defaultValue={defaultValue}
          value={internalValue}
          onChange={handleOnChange}
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
        />
      </InputResolver>
    )
  },
)
