import { Space } from 'antd'
import { Button } from 'components/atoms/Button/Button'
import Link from 'next/link'
import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTextStyle, defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SModal = styled.div`
  display: flex;
  justify-content: center;
`
export const SButtonSelection = styled(Button)<{ active: boolean }>`
  display: flex;
  padding: ${getSpacing([1, 2])};
  ${defaultTitleStyle(6)};
  background-color: ${(p) =>
    p.active ? p.theme.colors.blue_500 : p.theme.colors.grey_200};
  color: ${(p) =>
    p.active ? p.theme.colors.grey_000 : p.theme.colors.grey_600};

  :hover {
    cursor: pointer;
  }
`

export const SWrap = styled.div`
  display: flex;
  margin-bottom: ${getSpacing(4)};
  justify-content: space-around;
  gap: ${getSpacing(2)};

  & .style--default {
    border: none;
    background-color: ${(p) => p.theme.colors.grey_000};
    width: 48%;

    :active {
      color: ${(p) => p.theme.colors.grey_000};
      background-color: ${(p) => p.theme.colors.blue_500};
    }

    :focus {
      color: ${(p) => p.theme.colors.grey_000};
      background-color: ${(p) => p.theme.colors.blue_500};
    }

    :hover {
      color: ${(p) => p.theme.colors.grey_000};
      background-color: ${(p) => p.theme.colors.blue_500};
    }
  }
`

export const SFilterWrapp = styled.div`
  display: flex;
  width: 100vw;
  height: 570px;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.blueDefault};

  @media (max-width: 600px) {
    height: 590px;
  }

  @media (max-width: 450px) {
    height: 720px;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    height: 550px;
    margin-top: 0;
  }
`

export const SFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 736px;
  margin-top: ${getSpacing(8)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    width: 100%;
    margin-top: 0;
  }
`

export const STitle = styled.p`
  margin: 0;
  padding: 0;
  ${defaultTitleStyle(2)};
  color: ${(p) => p.theme.colors.grey_000};

  @media ${(p) => p.theme.breakpoints.mobile} {
    flex-direction: column;
    ${defaultTitleStyle(3)};
    gap: ${getSpacing(1)};
  }
`

export const SMapPosition = styled.p`
  padding: 0;
  margin: 0;
  ${defaultTitleStyle(5)}
  color: ${(p) => p.theme.colors.grey_300};
  min-width: 70px;
`

export const STitleWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${getSpacing(5)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    ${defaultTitleStyle(3)};
    padding: ${getSpacing([3.25, 3])};
    margin-bottom: ${getSpacing(-1.5)};
  }
  @media ${(p) => p.theme.breakpoints.tablet} {
    ${defaultTitleStyle(3)};
    padding: ${getSpacing([3.25, 3])};
    margin: ${getSpacing([1, 0, -1.5, 0])};
  }
`

export const SRadio = styled.div`
  ${defaultTitleStyle(5)};
  display: flex;
  align-items: center;
  max-width: max-content;
  background-color: ${(p) => p.theme.colors.blueGray};
  color: ${(p) => p.theme.colors.grey_000};
  padding: ${getSpacing([1, 2])};
  border-radius: 4px 12px 12px 12px;
  cursor: pointer;

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding: ${getSpacing([1, 1.75])};
  }
`

export const SButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${getSpacing([1])};

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-right: ${getSpacing(3)};
    padding-left: ${getSpacing(3)};
    height: 150px;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding: ${getSpacing([0, 7.5, 0, 3])};
    border-radius: 4px 12px 12px 12px;
    height: 190px;
    overflow: hidden;
  }
`

export const SInput = styled.div`
  min-width: 100%;
  display: flex;

  & * {
    height: 36px !important;
    display: flex;
    margin-bottom: ${getSpacing(1)};
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: ${getSpacing([1.5, 3])};
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding: ${getSpacing([3.25, 3])};
  }
`

export const SClearButton = styled.p`
  padding: 0;
  margin: 0;
  color: ${(p) => p.theme.colors.grey_450};
  ${defaultTextStyle(12)};
  cursor: pointer;
`

export const IconWrapp = styled.div`
  cursor: pointer;
`

export const SHeaderWrapp = styled.div`
  margin-right: 40%;
  display: flex;
  justify-content: space-between;
`

export const SWrappSpaceBlock = styled(Space.Compact)`
  @media (${(p) => p.theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 360px;
    margin: 0;
    padding-right: ${getSpacing(2)};
  }
`

export const SLinkInstructions = styled(Link)`
  display: flex;
  text-decoration: none;
  flex-direction: row;
  color: ${(p) => p.theme.colors.grey_600};
  ${defaultTextStyle(6)};
  max-width: 280px;
  gap: ${getSpacing(2)};
  color: ${(p) => p.theme.colors.blue_500};
  background-color: ${(p) => p.theme.colors.grey_000};
  border-radius: ${getSpacing(1)};
  margin: ${getSpacing([2, 0])};
  padding-left: ${getSpacing(1)};
`
