import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import { SBody, SFooter, SHeader, SModal } from './style/Modal.styled'

export interface IModalProps {
  open?: boolean
  modalContentMaxWidth?: string
  closable?: boolean
  header?: ReactNode
  footer?: ReactNode
  onCancel?: () => void
  children?: ReactNode
  onClose?: () => void
  zIndex?: number
}

export const Modal: FC<IModalProps> = ({
  header,
  footer,
  children,
  open,
  modalContentMaxWidth,
  onClose,
  zIndex,
  ...antdProps
}) => {
  const content = useMemo(
    () => (
      <>
        {header && <SHeader className="modal__header">{header}</SHeader>}
        <SBody className="modal__body">{children}</SBody>
        {footer && <SFooter className="modal__footer">{footer}</SFooter>}
      </>
    ),
    [children, footer, header],
  )

  return (
    <SModal
      footer={null}
      open={open}
      zIndex={zIndex}
      centered
      {...antdProps}
      onCancel={onClose}
      width={modalContentMaxWidth}>
      {content}
    </SModal>
  )
}
